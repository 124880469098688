import { useInfiniteQuery, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  sharedQueryClient,
  cacheKeys,
  useCustomInfiniteQuery,
} from "stores/storeConfig";
import { supportService } from "services/supportService";

const queryClient = sharedQueryClient;
const supportCacheKey = cacheKeys.SUPPORT;
const usersCacheKey = cacheKeys.USERS;
const userCacheKey = cacheKeys.USER;
const userVaultsCacheKey = cacheKeys.USERVAULTS;
const agentCacheKey = cacheKeys.AGENTS;
const agentActivitiesCacheKey = cacheKeys.AGENTS;

// Fetch all users by searchTerm
export function useUsers(searchTerm) {
  const cacheKey = [usersCacheKey];
  const query = () => supportService.getUsers(searchTerm);
  return useQuery(cacheKey, query, {
    enabled: !!searchTerm,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage} in useUsers`);
    },
  });
}

export function useUsersV2(request) {
  const cacheKey = [usersCacheKey, { ...request }];
  const query = () => supportService.getUsersV2(request);
  return useQuery(cacheKey, query, {
    enabled:
      !!request?.searchByUserProfileId || !!request?.searchByNameAndEmail,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage} in useUsers`);
    },
  });
}

export function useInfiniteUsersV3(request, enabled) {
  const cacheKey = [
    usersCacheKey,
    { query: request?.searchByUserProfileId ?? request?.searchByNameAndEmail },
  ];

  const apiCallFunction = ({ pageNumber = 0, pageSize = 50 }) => {
    const fullRequest = {
      ...request,
      pageNumber,
      pageSize,
    };

    return supportService.getUsersV3(fullRequest);
  };

  const getNextPageParam = (lastPage, allPages) => {
    const totalFetched = allPages?.reduce(
      (acc, page) => acc + page?.data?.userDataList?.length,
      0
    );

    return totalFetched < lastPage?.data?.fullLength ? allPages?.length : false;
  };

  return useInfiniteQuery(
    cacheKey,
    ({ pageParam }) => apiCallFunction({ pageNumber: pageParam }),
    {
      initialPageParam: 1,
      getNextPageParam,
      enabled,
    }
  );
}

//Fetch user by userProfileId
export function useUser(userProfileId) {
  const cacheKey = [userCacheKey, { userProfileId }];
  const query = () => supportService.getUser(userProfileId);
  return useQuery(cacheKey, query, {
    enabled: !!userProfileId,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage} (useUser)`);
    },
  });
}

//Fetch all agents
export function useAgents() {
  const cacheKey = [agentCacheKey];
  const query = () => supportService.getAgents();
  return useQuery(cacheKey, query, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage} in useAgents`);
    },
  });
}

//Fetch all activities for a given agent
export function useAgentActivities(agentId) {
  const cacheKey = [agentActivitiesCacheKey, { agentId }];
  const query = () => supportService.getAgentActivities(agentId);
  return useQuery(cacheKey, query, {
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage} in useAgents`);
    },
  });
}

//Fetch user vaults by userProfileId
export function useUserVaults(userProfileId) {
  const cacheKey = [userVaultsCacheKey, { userProfileId }];
  const query = () => supportService.getUserVaults(userProfileId);
  return useQuery(cacheKey, query, {
    enabled: !!userProfileId,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    onError: (error) => {
      toast.error(`${error.response?.data?.errorMessage} (useUserVaults)`);
    },
  });
}

// Lock User
export async function lockUser(userProfileId) {
  const response = await supportService.lockUser(userProfileId);
  await Promise.all([
    queryClient.invalidateQueries([userCacheKey, { userProfileId }]),
  ]);
  return response;
}

// Unlock User
export async function unlockUser(userProfileId) {
  const response = await supportService.unlockUser(userProfileId);
  await Promise.all([
    queryClient.invalidateQueries([userCacheKey, { userProfileId }]),
  ]);
  return response;
}

// Send Password Reset
export async function sendPasswordReset(userProfileId) {
  const response = await supportService.sendPasswordReset(userProfileId);
  await Promise.all([
    queryClient.invalidateQueries([userCacheKey, { userProfileId }]),
  ]);
  return response;
}

// Send Verification Code.
export async function sendCode(userProfileId, method = "email") {
  const response = await supportService.addCode(userProfileId, method);
  await Promise.all([
    queryClient.invalidateQueries([userCacheKey, { userProfileId }]),
  ]);
  return response;
}

// Verify Verification Code
export async function verifyCode(userProfileId, code) {
  const response = await supportService.updateCode(userProfileId, code);
  await Promise.all([
    queryClient.invalidateQueries([userCacheKey, { userProfileId }]),
  ]);
  return response;
}

// End User Session
export async function endUserSession(userProfileId) {
  const response = await supportService.endUserSession(userProfileId);
  await Promise.all([
    queryClient.invalidateQueries([userCacheKey, { userProfileId }]),
  ]);
  return response;
}

// Revive disabled vault
export async function reviveVault(vaultId, vaultRevivalInfo) {
  const response = await supportService.reviveVault(vaultId, vaultRevivalInfo);
  await Promise.all([queryClient.invalidateQueries([userVaultsCacheKey])]);
  return response;
}

// Disable vault
export async function disableVault(vaultId, supportTicket) {
  const response = await supportService.disableVault(vaultId, supportTicket);
  await Promise.all([queryClient.invalidateQueries([userVaultsCacheKey])]);
  return response;
}

export async function releaseVault(vaultId, revert) {
  const response = await supportService.releaseVault(vaultId, revert);
  await Promise.all([queryClient.invalidateQueries([userVaultsCacheKey])]);
  return response;
}
