import React from "react";
import PropTypes from "prop-types";
import styles from "./Icon.module.scss";

export default function Icon({ type, size = "medium", color }) {
  return (
    <span className="icon">
      <span
        className={`material-icons-outlined ${styles[size]} ${styles[color]}`}
      >
        {type}
      </span>
    </span>
  );
}

Icon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge", "xxlarge"]),
  color: PropTypes.string,
};
