import React from "react";
import PropTypes from "prop-types";
import NoResults from "components/shared/noresults/NoResults";

function UserSupportActivity({ user }) {
  return (
    <NoResults
      title="Coming Soon"
      subtitle="This section will be available soon."
    />
  );
}

UserSupportActivity.propTypes = {};

export default UserSupportActivity;
