import React from "react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import AgentActivities from "components/agents/AgentActivities";
import { useRouter } from "util/router";

export function AgentActivitiesPage({}) {
  const router = useRouter();

  return <AgentActivities agentId={router.query.agentId} />;
}

export default withAuthenticationRequired(AgentActivitiesPage, {
  onRedirecting: () => <PageLoader />,
});
