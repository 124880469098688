import React, { useState, useMemo, useEffect } from "react";
import List from "components/shared/list/List";
import styles from "./Activity.module.scss";
import cx from "classnames";
import { getActivityDetail } from "util/activityBuilder";
import NoContent from "components/shared/nocontent/NoContent";
import Filter from "components/shared/filter/Filter";
import FormField from "components/shared/formfield/FormField";
import moment from "moment";
import { useDebounce } from "hooks/useDebounce";
import NoResults from "components/shared/noresults/NoResults";
import { activityBelongsToFilter } from "util/activityFilter";
import activityTypes from "enums/activityTypes";
import ActivityDataModal from "./modals/ActivityDataModal";

export default function UserActivity({ activities }) {
  const [filteredActivities, setFilteredActivities] = useState(activities);
  const [showData, setShowData] = useState(false);
  const [activeActivity, setActiveActivity] = useState(activities[0]);

  //Filter
  const [search, setSearch] = useState("");
  const [activeDateFilter, setActiveDateFilter] = useState();
  const [activeFilter, setActiveFilter] = useState();
  const [filterDate, setFilterDate] = useState("All Time");
  const [filterDateBy, setFilterDateBy] = useState(null);
  const [filterTitle, setFilterTitle] = useState("All Activities");
  const [filterBy, setFilterBy] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);

  function filterByDate(activity) {
    let f = moment().subtract(filterDateBy, "days").unix();
    let ts = moment(parseInt(activity?.timestamp)).unix();
    if (filterDateBy === null) return true;
    return ts > f;
  }

  function filterBySearchTerm(activity) {
    if (!activity?.data?.activityDescription) return true;
    return activity?.data?.activityDescription
      ?.toLowerCase()
      .includes(debouncedSearchTerm.toLowerCase());
  }

  //Set our debouncedSearchTerm
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  //Memoization of activities and filters
  useMemo(
    () =>
      setFilteredActivities(
        activities
          ?.filter(filterByDate)
          ?.filter((activity) => activityBelongsToFilter(activity, filterBy))
          ?.filter(filterBySearchTerm)
      ),
    [activities, debouncedSearchTerm, filterBy, filterDateBy]
  );

  //Filter
  function onFilterItemClick(name, filter) {
    setFilterTitle(name);
    setFilterBy(filter);
  }
  function onFilterDateClick(name, filter) {
    setFilterDate(name);
    setFilterDateBy(filter);
  }

  return (
    <>
      <div className="container mt-2">
        <div className={cx("content", styles.content)}>
          <div className="columns">
            <div className="column">
              {activities?.length > 0 ? (
                <div className={styles.list}>
                  <List>
                    <List.SearchRow>
                      <List.SearchInput>
                        <FormField
                          name="search"
                          type="text"
                          size="small"
                          placeholder="Search"
                          onChange={handleSearchChange}
                          autoComplete="off"
                          hasMargin={false}
                          id="search-input"
                        />
                      </List.SearchInput>
                      <List.SearchFilters>
                        <Filter
                          title={filterDate}
                          filterRef="filterDateBy"
                          onClick={() => {}}
                          active={activeDateFilter === "filterDateBy"}
                          id="filter-date-by-dropdown"
                        >
                          <Filter.FilterItem
                            id="filter-by-all-time"
                            name="All Time"
                            onClick={() => onFilterDateClick("All Time", null)}
                          />
                          <Filter.FilterItem
                            id="filter-by-90-days"
                            name="Last 90 Days"
                            onClick={() =>
                              onFilterDateClick("Last 90 Days", 90)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-30-days"
                            name="Last 30 Days"
                            onClick={() =>
                              onFilterDateClick("Last 30 Days", 30)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-7-days"
                            name="Last 7 Days"
                            onClick={() => onFilterDateClick("Last 7 Days", 7)}
                          />
                          <Filter.FilterItem
                            id="filter-by-24-hours"
                            name="Last 24 Hours"
                            onClick={() =>
                              onFilterDateClick("Last 24 Hours", 1)
                            }
                          />
                        </Filter>
                        <Filter
                          title={filterTitle}
                          filterRef="filterBy"
                          onClick={() => {}}
                          active={activeFilter === "filterBy"}
                          id="filter-by-dropdown"
                        >
                          <Filter.FilterItem
                            id="filter-by-all-activities"
                            name="All Activities"
                            onClick={() =>
                              onFilterItemClick(
                                "All Activities",
                                activityTypes.ALL
                              )
                            }
                          />
                          <hr className="mt-1 mb-1" />
                          <Filter.FilterItem
                            id="filter-by-billing"
                            name="Billing"
                            onClick={() =>
                              onFilterItemClick(
                                "Billing",
                                activityTypes.BILLING
                              )
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-terms"
                            name="Terms of Service"
                            onClick={() =>
                              onFilterItemClick(
                                "Terms of Service",
                                activityTypes.TERMS
                              )
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-sign-in"
                            name="Sign In"
                            onClick={() =>
                              onFilterItemClick("Sign In", activityTypes.SIGNIN)
                            }
                          />
                          <hr className="mt-1 mb-1" />
                          <Filter.FilterItem
                            id="filter-by-add"
                            name="Add"
                            onClick={() =>
                              onFilterItemClick("Add", activityTypes.ADD)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-update"
                            name="Update"
                            onClick={() =>
                              onFilterItemClick("Update", activityTypes.UPDATE)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-remove"
                            name="Remove"
                            onClick={() =>
                              onFilterItemClick("Remove", activityTypes.REMOVE)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-delete"
                            name="Delete"
                            onClick={() =>
                              onFilterItemClick("Delete", activityTypes.DELETE)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-share"
                            name="Share"
                            onClick={() =>
                              onFilterItemClick("Share", activityTypes.SHARE)
                            }
                          />

                          <Filter.FilterItem
                            id="filter-by-watch"
                            name="Watch"
                            onClick={() =>
                              onFilterItemClick("Watch", activityTypes.WATCH)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-view"
                            name="View"
                            onClick={() =>
                              onFilterItemClick("View", activityTypes.VIEW)
                            }
                          />
                          <Filter.FilterItem
                            id="filter-by-view"
                            name="Download"
                            onClick={() =>
                              onFilterItemClick(
                                "Download",
                                activityTypes.DOWNLOAD
                              )
                            }
                          />
                        </Filter>
                      </List.SearchFilters>
                    </List.SearchRow>
                    {filteredActivities?.map((activity, index) => {
                      const activityDetail = getActivityDetail(activity, true);
                      return (
                        <List.Row
                          key={activity.activityId}
                          onClick={() => {
                            setActiveActivity(activity);
                            setShowData(true);
                          }}
                        >
                          <List.Text
                            title={activityDetail.header}
                            subtitle={activityDetail.action}
                          />
                          <span className={styles.date}>
                            {activityDetail.date}
                          </span>
                        </List.Row>
                      );
                    })}
                    {filteredActivities?.length === 0 && (
                      <NoResults
                        subtitle="There are no activities that match the filters applied. Please try again."
                        size="large"
                      />
                    )}
                  </List>
                </div>
              ) : (
                <NoContent
                  title="No Recent Activity"
                  subtitle="As you use your Vault, the most recent activities will appear here."
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showData && (
        <ActivityDataModal
          activity={activeActivity}
          onCancel={() => setShowData(false)}
        />
      )}
    </>
  );
}
