import agentActivityTypeMap from "enums/agentActivityTypeMap";
import agentActivityTypes from "enums/agentActivityTypes";

export function agentActivityBelongsToFilter(activity, activityType = null) {
  if (
    !activityType ||
    activityType.length === 0 ||
    activityType === agentActivityTypes.ALL
  )
    return true;

  const types = agentActivityTypeMap[activityType];

  return types.includes(activity?.data?.activityType);
}
