import React from "react";
import PropTypes from "prop-types";
import { Link } from "util/router";
import styles from "./BackButton.module.scss";
import cx from "classnames";

export default function BackButton({ link }) {
  return (
    <div className={styles.backBtnContainer}>
      <Link className={cx("button", styles.backBtn)} to={link} id="back-btn">
        <span className="icon">
          <span className={cx("material-icons-outlined", styles.icon)}>
            arrow_back
          </span>
        </span>
        <span>Back</span>
      </Link>
    </div>
  );
}

BackButton.propTypes = { link: PropTypes.string.isRequired };
