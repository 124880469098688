export const AgentActivityType = {
  RETRIEVED_AGENT_ACTIVITY: "RETRIEVED_AGENT_ACTIVITY",
  SEARCHED_AGENTS_ACTIVITY: "SEARCHED_AGENTS_ACTIVITY",
  SENT_VERIFICATION_CODE_TO_USER: "SENT_VERIFICATION_CODE_TO_USER",
  VERIFIED_OTP_PROVIDED_BY_USER: "VERIFIED_OTP_PROVIDED_BY_USER",
  RETRIEVED_USER_PROFILE_BY_USER_PROFILE_ID:
    "RETRIEVED_USER_PROFILE_BY_USER_PROFILE_ID",
  LOCKED_USER: "LOCKED_USER",
  SEARCHED_FOR_USER_PROFILES: "SEARCHED_FOR_USER_PROFILES",
  SENT_PASSWORD_RESET: "SENT_PASSWORD_RESET",
  UNLOCKED_USER: "UNLOCKED_USER",
  RETRIEVED_VAULTS_FOR_USER_BY_USER_PROFILE_ID:
    "RETRIEVED_VAULTS_FOR_USER_BY_USER_PROFILE_ID",
  VAULT_DISABLED: "VAULT_DISABLED",
  VAULT_RELEASED: "VAULT_RELEASED",
  VAULT_RELEASE_REVERTED: "VAULT_RELEASE_REVERTED",
  VAULT_REVIVED: "VAULT_REVIVED",
  END_USER_SESSION: "END_USER_SESSION",
};
