import React, { useState, useEffect, useRef } from "react";
import List from "components/shared/list/List";
import NoResults from "components/shared/noresults/NoResults";
import { useDebounce } from "hooks/useDebounce";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import { useInfiniteUsersV3 } from "stores/supportStore";
import UsersPage from "./UsersPage";
import PageLoader from "components/shared/pageloader/PageLoader";
import NoUsers from "components/shared/nousers/NoUsers";
import FormField from "components/shared/formfield/FormField";
import { maybeUserProfileId } from "util/helpers";

function Users({ useScrollableTargetRef = false }) {
  const [search, setSearch] = useState(null);
  const [usersRequest, setUsersRequest] = useState(null);
  const debouncedSearchTerm = useDebounce(search, 300);

  //Infinite Scroll Ref
  const usersRef = useRef(null);

  const isEnabled =
    !!usersRequest &&
    (!!usersRequest?.searchByUserProfileId ||
      !!usersRequest?.searchByNameAndEmail) &&
    (usersRequest?.searchByUserProfileId?.length > 3 ||
      usersRequest?.searchByNameAndEmail?.length > 3);

  const {
    data: users,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    error,
  } = useInfiniteUsersV3(usersRequest, isEnabled);
  useInfiniteScroll({
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...(useScrollableTargetRef && { scrollableTargetRef: usersRef }),
  });

  const buildGetUsersRequest = (searchTerm) => {
    let request;

    if (maybeUserProfileId(searchTerm)) {
      request = {
        searchByUserProfileId: debouncedSearchTerm,
      };
    } else {
      request = {
        searchByNameAndEmail: debouncedSearchTerm,
      };
    }

    return request;
  };

  useEffect(() => {
    const request = buildGetUsersRequest(debouncedSearchTerm);
    setUsersRequest(request);
  }, [debouncedSearchTerm]);

  const handleSearchChange = (e) => {
    if (e.target.value === "") {
      setSearch(null);
    } else {
      setSearch(e.target.value);
    }
  };

  return (
    <div className="mx-5 mb-5">
      <div ref={usersRef}>
        <List.SearchRow
          hideBottomBorder
          paddingSize="none"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <List.SearchInput>
            <FormField
              name="search"
              type="text"
              size="small"
              placeholder="Search"
              onChange={handleSearchChange}
              autoComplete="off"
              hasMargin={false}
              id="search-input"
            />
          </List.SearchInput>
        </List.SearchRow>

        <List hideLastRowBorder hasBorder>
          <List.Row>
            <List.Text title="Name" />
            <List.Text title="Email" />
            <List.Text title="UserProfileId" shrinkPadding />
            <List.Text title="Status" shrinkPadding />
            <List.Text title="Org Id" shrinkPadding />
          </List.Row>

          {users?.pages?.map((page, i) => (
            <UsersPage page={page} key={i} />
          ))}
        </List>
        {(isLoading || isFetchingNextPage) && <PageLoader />}
        {users?.pages?.[0]?.data?.userDataList?.length === 0 && (
          <NoResults
            subtitle="There are no users that match the query. Please try again."
            size="large"
          />
        )}
        {!isLoading &&
          !isFetchingNextPage &&
          !users?.pages?.[0]?.data?.userDataList &&
          !error && <NoUsers />}
        {error && <NoUsers title="There was an error loading users." />}
      </div>
    </div>
  );
}

Users.propTypes = {};

export default Users;
