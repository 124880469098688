import React from "react";
import PropTypes from "prop-types";
import styles from "./Modal.module.scss";
import Button from "../button/Button";
import cx from "classnames";

export default function Modal({ onClose, children, preventOnClose, isLarge }) {
  return (
    <div className="modal is-active" id="modal">
      <div
        className="modal-background"
        onClick={() => !preventOnClose && onClose()}
      />
      <div
        className={cx(`${styles.modalCard} modal-card`, {
          [styles.isLarge]: isLarge,
        })}
        id="modal-card"
      >
        {children}
      </div>
    </div>
  );
}

function Header({ title, onClose, preventOnClose }) {
  return (
    <header className="modal-card-head" id="modal-card-head">
      <h2 className={styles.modalCardTitle} id="modal-card-title">
        {title}
      </h2>
      {!preventOnClose && (
        <span
          className={`${styles.modalCardClose} icon`}
          onClick={() => onClose()}
          id="modal-close-button"
        >
          <span className="material-icons-outlined" id="modal-card-close">
            close
          </span>
        </span>
      )}
    </header>
  );
}
function Content({ hasScroll, children, disablePadding, height }) {
  return (
    <div
      className={cx(`modal-card-body ${styles.modalCardBody}`, {
        [styles.hasScroll]: hasScroll,
        [styles.disablePadding]: disablePadding,
      })}
      id="modal-card-body"
      style={height ? { height: height } : null}
    >
      {children}
    </div>
  );
}

function Footer({
  cancelText = "Cancel",
  submitText = "Submit",
  submitColor,
  onCancel,
  onSubmit,
  isSubmitting,
  hideCancel,
  hideSubmit,
  isDisabled,
  children,
}) {
  return (
    <div className="modal-card-foot" id="modal-card-footer">
      <div
        className="is-justify-content-flex-start"
        id="modal-card-footer-children"
      >
        {children}
      </div>
      <div
        className="is-justify-content-flex-end"
        id="modal-card-footer-buttons"
      >
        {!hideCancel && (
          <Button
            text={cancelText}
            type="button"
            color="secondary"
            onClick={() => onCancel()}
            id="modal-cancel-btn"
            disabled={isSubmitting || isDisabled}
            hasSecondaryMargin
          />
        )}
        {!hideSubmit && (
          <Button
            text={submitText}
            disabled={isSubmitting || isDisabled}
            isLoading={isSubmitting}
            id="modal-submit-btn"
            onClick={() => onSubmit()}
            color={submitColor}
          />
        )}
      </div>
    </div>
  );
}

Modal.Header = Header;
Modal.Body = Content;
Modal.Footer = Footer;

Modal.propTypes = {
  /**
   * The function that should fire upon clicking the shrouded background of the Modal
   */
  onClose: PropTypes.func,
};

Modal.Header.propTypes = {
  /**
   * Title that will be displayed in the Modal header
   */
  title: PropTypes.string.isRequired,
  /**
   * The function that should fire upon clicking the X button in the Modal header
   */
  onClose: PropTypes.func,
};

Modal.Body.propTypes = {
  /**
   * The content to display in the main body section of the Modal
   */
  children: PropTypes.node,
};

Modal.Footer.propTypes = {
  /**
   * The text to display within the Cancel button of the Modal footer
   */
  cancelText: PropTypes.string,
  /**
   * The text to display within the Submit button of the Modal footer
   */
  submitText: PropTypes.string,
  /**
   * The function that should fire upon clicking the Cancel button in the Modal footer
   */
  onCancel: PropTypes.func,
  /**
   * The function that should fire upon clicking the Submit button in the Modal footer
   */
  onSubmit: PropTypes.func,
  /**
   * A boolean check to determine if a Submit event is currently happening.  If it is, will disable the Submit button and display a loading icon.
   */
  isSubmitting: PropTypes.bool,
};
