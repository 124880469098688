import React from "react";
import { useRouter } from "util/router";
import User from "components/user/User";
import PageLoader from "components/shared/pageloader/PageLoader";
import { withAuthenticationRequired } from "@auth0/auth0-react";

export function UserPage({}) {
  const router = useRouter();

  return (
    <User
      userProfileId={router.query.userProfileId}
      section={router.query.section}
    />
  );
}

export default withAuthenticationRequired(UserPage, {
  onRedirecting: () => <PageLoader />,
});
