import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";

export function useAuth0JWT() {
  const [decodedToken, setDecodedToken] = useState(null);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        const decoded = jwtDecode(token);
        setDecodedToken(decoded);
      } catch (error) {
        console.error("Error fetching token:", error);
      }
    };

    fetchToken();
  }, [getAccessTokenSilently]);

  return decodedToken;
}
