export const ActivityType = {
  PERSON_VIEWED: "PERSON_VIEWED",
  PERSON_ADDED: "PERSON_ADDED",
  PERSON_INVITED_TO_VAULT: "PERSON_INVITED_TO_VAULT",
  PERSON_ROLE_CHANGED: "PERSON_ROLE_CHANGED",
  PERSON_UPDATED: "PERSON_UPDATED",
  PERSON_DELETED: "PERSON_DELETED",
  PERSON_ACCEPTED_INVITE_TO_VAULT: "PERSON_ACCEPTED_INVITE_TO_VAULT",
  IMAGE_ADDED_TO_PERSON: "IMAGE_ADDED_TO_PERSON",
  IMAGE_DELETED_FROM_PERSON: "IMAGE_DELETED_FROM_PERSON",
  PERSON_INVITE_REMOVED_FROM_VAULT: "PERSON_INVITE_REMOVED_FROM_VAULT",
  PERSON_INVITE_RESENT: "PERSON_INVITE_RESENT",
  PERSON_INVITE_CANCELLED: "PERSON_INVITE_CANCELLED",

  DOCUMENT_DOWNLOADED: "DOCUMENT_DOWNLOADED",
  DOCUMENT_VIEWED: "DOCUMENT_VIEWED",
  DOCUMENT_ADDED: "DOCUMENT_ADDED",
  DOCUMENT_UPDATED: "DOCUMENT_UPDATED",
  DOCUMENT_DELETED: "DOCUMENT_DELETED",

  SHARE_PERMISSION_ADDED: "SHARE_PERMISSION_ADDED",
  SHARE_PERMISSION_REMOVED: "SHARE_PERMISSION_REMOVED",

  THING_VIEWED: "THING_VIEWED",
  THING_ADDED: "THING_ADDED",
  THING_UPDATED: "THING_UPDATED",
  THING_DELETED: "THING_DELETED",
  IMAGE_ADDED_TO_THING: "IMAGE_ADDED_TO_THING",
  IMAGE_DELETED_FROM_THING: "IMAGE_DELETED_FROM_THING",

  VAULT_NAME_UPDATED: "VAULT_NAME_UPDATED",
  VAULT_ACCESSED: "VAULT_ACCESSED",
  VAULT_VIEWED: "VAULT_VIEWED",

  USER_PROFILE_UPDATED: "USER_PROFILE_UPDATED",
  USER_LOGIN_SUCCESSFUL: "USER_LOGIN_SUCCESSFUL",
  BIOMETRIC_LOGIN_SUCCESSFUL: "BIOMETRIC_LOGIN_SUCCESSFUL",
  USER_PASSWORD_CHANGE_SUCCESSFUL: "USER_PASSWORD_CHANGE_SUCCESSFUL",

  MEDIA_ADDED: "MEDIA_ADDED",
  MEDIA_DELETED: "MEDIA_DELETED",
  MEDIA_DOWNLOADED: "MEDIA_DOWNLOADED",
  MEDIA_VIDEO_WATCHED: "MEDIA_VIDEO_WATCHED",
  MEDIA_UPDATED: "MEDIA_UPDATED",

  NOTE_ADDED: "NOTE_ADDED",
  NOTE_UPDATED: "NOTE_UPDATED",
  NOTE_DELETED: "NOTE_DELETED",

  PLACE_VIEWED: "PLACE_VIEWED",
  PLACE_ADDED: "PLACE_ADDED",
  PLACE_UPDATED: "PLACE_UPDATED",
  PLACE_DELETED: "PLACE_DELETED",
  IMAGE_ADDED_TO_PLACE: "IMAGE_ADDED_TO_PLACE",
  IMAGE_DELETED_FROM_PLACE: "IMAGE_DELETED_FROM_PLACE",

  ASSET_LINK_ADDED: "ASSET_LINK_ADDED",
  ASSET_LINK_REMOVED: "ASSET_LINK_REMOVED",

  USER_TERMS_ACCEPTED: "USER_TERMS_ACCEPTED",
  INVOICE_PAID: "INVOICE_PAID",
  USER_PROFILE_CREATED: "USER_PROFILE_CREATED",
};
