import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Button.module.scss";

export default function Button({
  text,
  icon,
  type = "submit",
  color = "primary",
  size = "medium",
  isFullWidth,
  hasSecondaryMargin,
  isLoading,
  disabled,
  onClick,
  id,
  ...otherProps
}) {
  return (
    <button
      className={cx(`button is-${size}`, styles.actionBtn, styles[color], {
        "is-loading": isLoading,
        "is-fullwidth": isFullWidth,
        "is-rounded": size === "small",
        "mr-3": hasSecondaryMargin,
      })}
      disabled={disabled}
      type={type}
      id={id}
      onClick={onClick}
      {...otherProps}
    >
      {icon ? (
        <span className="icon-text">
          <span className="icon mr-2">
            <span className="material-icons-outlined">{icon}</span>
          </span>
          {text}
        </span>
      ) : (
        text
      )}
    </button>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "text",
    "danger",
    "dangerInverse",
  ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};
