import React from "react";
import User from "./User";

export default function UsersPage({ page }) {
  return (
    <>
      {page?.data?.userDataList?.map((user) => (
        <User user={user} key={user?.userProfile?.userProfileId} />
      ))}
    </>
  );
}
