import React from "react";
import "styles/global.scss";
import "styles/animation.scss";
import { Switch, Route, Router } from "util/router.js";
import Navbar from "components/shared/navbar/Navbar";
import Home from "views/Home";
import Profile from "views/Profile";
import ExternalApi from "views/ExternalApi";
import { useAuth0 } from "@auth0/auth0-react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { QueryClientProvider } from "stores/storeConfig";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WithAxios from "auth/WithAxios";
import UserPage from "pages/user";
import { UsersPage } from "pages/users";
import { ErrorBoundary } from "components/ErrorBoundary";
import { AgentsPage } from "pages/agents";
import { AgentActivitiesPage } from "pages/agentActivities";

const fade = cssTransition({
  enter: "fade-in-top",
  exit: "fade-out-top",
});

const App = () => {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider>
        <WithAxios>
          <Router>
            <div>
              <Navbar />
              <Switch>
                <Route path={["/", "/dashboard"]} exact component={Home} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/external-api" component={ExternalApi} />
                <Route path="/users" exact component={UsersPage} />
                <Route path="/agents" exact component={AgentsPage} />
                <Route
                  path="/agents/:agentId"
                  component={AgentActivitiesPage}
                />
                <Route
                  exact
                  path={[
                    "/users/:userProfileId",
                    "/users/:userProfileId/:section",
                  ]}
                  component={UserPage}
                />
              </Switch>
            </div>
          </Router>
          <ReactQueryDevtools initialIsOpen={false} />
          <ToastContainer
            position="top-center"
            autoClose={4000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            closeButton={false}
            icon={false}
            transition={fade}
            className="toastify-notification"
          />
        </WithAxios>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
