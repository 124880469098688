import activityTypeMap from "enums/activityTypeMap";
import activityTypes from "enums/activityTypes";

export function activityBelongsToFilter(activity, activityType = null) {
  if (
    !activityType ||
    activityType.length === 0 ||
    activityType === activityTypes.ALL
  )
    return true;

  const types = activityTypeMap[activityType];

  return types.includes(activity?.data?.activityType);
}
