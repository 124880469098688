import { get, add, update, del } from "./serviceConfig";

const API_BASE_URL = process.env.REACT_APP_ORGANIZATIONS_SVC_API_URL;

export const organizationsService = {
  //Organization
  getOrganization(orgId) {
    return get(`${API_BASE_URL}/organizations/v1/organizations/${orgId}`);
  },
};
