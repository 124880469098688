import React, { useState } from "react";
import { Link, NavLink } from "util/router.js";
import Logo from "svgs/logo/Logo";
import AccountDropdown from "./AccountDropdown";
import "./Navbar.scss";
import styles from "./Navbar.module.scss";
import cx from "classnames";
import customerSupportPermissions from "enums/permissions/customerSupportPermissions";
import CustomerSupportPermissionValidator from "components/shared/permissions/CustomerSupportPermissionValidator";

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <nav className={cx("navbar", styles.navbar)}>
        <div className={cx("container", styles.container)}>
          <div className="navbar-brand">
            <div className="navbar-item">
              <Link
                to={`/dashboard`}
                className="navbar-link is-arrowless"
                id="navbar-logo-btn"
              >
                <Logo className="logo" alt="Logo" showText />
                <h3 className={styles.siteName}>Customer Support Portal</h3>
              </Link>
            </div>
            <div
              className={
                "navbar-burger burger" + (menuOpen ? " is-active" : "")
              }
              onClick={() => setMenuOpen(!menuOpen)}
              id="navbar-mobile-menu-btn"
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div className={"navbar-menu" + (menuOpen ? " is-active" : "")}>
            <div className={cx("navbar-start", styles.navbarMiddle)}>
              <NavLink
                className="navbar-item"
                to="/dashboard"
                activeClassName="is-active"
                id="navbar-dashboard-btn"
                onClick={() => {
                  menuOpen && setMenuOpen(!menuOpen);
                }}
              >
                Dashboard
              </NavLink>
              <CustomerSupportPermissionValidator
                permissions={[
                  customerSupportPermissions.USER_READ_PERMISSION,
                  customerSupportPermissions.USER_VAULTS_READ_PERMISSION,
                ]}
              >
                <NavLink
                  className="navbar-item"
                  to="/users"
                  activeClassName="is-active"
                  id="navbar-users-btn"
                  onClick={() => {
                    menuOpen && setMenuOpen(!menuOpen);
                  }}
                >
                  Users
                </NavLink>
              </CustomerSupportPermissionValidator>
              <CustomerSupportPermissionValidator
                permissions={[
                  customerSupportPermissions.AGENT_READ_PERMISSION,
                  customerSupportPermissions.AGENT_ACTIVITY_READ_PERMISSION,
                ]}
              >
                <NavLink
                  className="navbar-item"
                  to="/agents"
                  activeClassName="is-active"
                  id="navbar-agents-btn"
                  onClick={() => {
                    menuOpen && setMenuOpen(!menuOpen);
                  }}
                >
                  Agents
                </NavLink>
              </CustomerSupportPermissionValidator>
            </div>
            <div className="navbar-end">
              <AccountDropdown />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
