export default {
  ALL: "all",
  ADD: "add",
  UPDATE: "update",
  REMOVE: "remove",
  DELETE: "delete",
  SHARE: "share",
  VIEW: "view",
  DOWNLOAD: "download",
  WATCH: "watch",
  SIGNIN: "signin",
  BILLING: "billing",
  TERMS: "terms",
};
