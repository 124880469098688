import wellKnownPolicy from "./wellKnownPolicy";
import wellKnownPolicyGroup from "./wellKnownPolicyGroup";

export default {
  DESCRIPTIONS: {
    [wellKnownPolicy.PERSON_ADD_ANYTHING]: "Add anything",
    [wellKnownPolicy.PERSON_ADD_LIMITED]: "Add limited",
    [wellKnownPolicy.PERSON_EDIT_ANYTHING]: "Edit anything",
    [wellKnownPolicy.PERSON_EDIT_LIMITED]: "Edit limited",
    [wellKnownPolicy.PERSON_DELETE_ANYTHING]: "Delete anything",
    [wellKnownPolicy.PERSON_DELETE_LIMITED]: "Delete limited",
    [wellKnownPolicy.ROLE_INVITED_USER]: "View limited",
  },
  DESCRIPTIONS_OWNER: {
    [wellKnownPolicy.PERSON_ADD_ANYTHING]: "All - Vault Owner",
    [wellKnownPolicy.PERSON_ADD_ANYTHING]: "All - Co-Owner",
  },
};
