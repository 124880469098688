import React from "react";
import styles from "./NoUsers.module.scss";

export default function NoUsers({ title = "No Users" }) {
  return (
    <div className={`${styles.noUser}`} id="no-content-container">
      <h1 id="no-content-title" className="mb-3">
        {title}
      </h1>
    </div>
  );
}
