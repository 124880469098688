import React from "react";
import PermissionPolicyGroup from "./PermissionPolicyGroup";
import wellKnownPolicyGroup from "enums/permissions/wellKnownPolicyGroup";

export default function PermissionOptions({
  data,
  personIsOwner,
  personIsCoOwner,
  wellKnownPolicyGroup,
}) {
  return (
    <>
      <PermissionPolicyGroup
        data={data}
        policyGroup={wellKnownPolicyGroup}
        personIsOwner={personIsOwner}
        personIsCoOwner={personIsCoOwner}
      />
    </>
  );
}

PermissionOptions.propTypes = {};
