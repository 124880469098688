import cx from "classnames";
import styles from "./ErrorBoundary.module.scss";
import LogoSvg from "svgs/logo/LogoSvg";
import React from "react";

const ErrorView = ({
  title = "Something went wrong.",
  subTitle = "Please reload the page or try again later.",
}) => (
  <div className={cx(styles.container)}>
    <LogoSvg className={styles.logo} showText />

    <main className={`${styles.containerMain} has-text-centered`}>
      <h1 className="mb-3">{title}</h1>
      <h3 className="mb-3">{subTitle}</h3>
    </main>
  </div>
);

export default ErrorView;
