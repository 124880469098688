import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { cacheKeys, sharedQueryClient } from "stores/storeConfig";
import { organizationsService } from "services/organizationsService";

const organizationCacheKey = cacheKeys.ORGANIZATION;

// Fetch organization data (hook)
export function useOrganization(orgId) {
  const cacheKey = [organizationCacheKey, { orgId }];
  const query = () => organizationsService.getOrganization(orgId);
  return useQuery(cacheKey, query, {
    enabled: !!orgId,
  });
}
