import React from "react";
import PropTypes from "prop-types";
import { useUserVaults } from "stores/supportStore";
import Vault from "./Vault";
import PageLoader from "components/shared/pageloader/PageLoader";
import NoResults from "components/shared/noresults/NoResults";

function UserVaults({ user, vaults }) {
  return (
    <div className="columns">
      <div className="column">
        <div className="tile is-ancestor">
          <div className="tile is-parent is-vertical mt-2">
            {!vaults?.data && (
              <NoResults
                title="No vault data available"
                subtitle="This user is probably a free user or there was an error retrieving vault data."
              />
            )}
            {vaults?.data?.map((vault) => (
              <Vault vault={vault} key={vault?.vault?.vaultId} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

UserVaults.propTypes = {};

export default UserVaults;
