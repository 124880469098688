import React from "react";
import PropTypes from "prop-types";

export default function Message({ children }) {
  return children;
}

function Success({ text }) {
  return (
    <span className="icon-text">
      <span className="icon">
        <span className="material-icons-outlined">check_circle</span>
      </span>
      <span className="pl-2" id="success-message-text">
        {text}
      </span>
    </span>
  );
}

Message.Success = Success;

Message.Success.propTypes = {
  /**
   * Text that will be displayed in the message
   */
  text: PropTypes.string.isRequired,
};
