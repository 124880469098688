import React from "react";
import PropTypes from "prop-types";
import styles from "./TextButton.module.scss";
import cx from "classnames";

function renderSpecialCharacter(specialCharacter) {
  if (specialCharacter === "reg") return <span>&reg;</span>;
}

const TextButton = React.forwardRef(
  (
    {
      onClick,
      id,
      text,
      icon = "add",
      fontSize,
      isSimple,
      dark,
      specialCharacter,
      color,
      ...otherProps
    },
    ref
  ) => (
    <button
      className={cx(styles.btn, styles[color], {
        [styles.isSimple]: isSimple,
        [styles.dark]: dark,
      })}
      onClick={onClick}
      id={id}
      ref={ref}
      {...otherProps}
    >
      <span className="icon-text">
        <span className="icon">
          <span className="material-icons-outlined">{icon}</span>
        </span>
        <span className={styles[fontSize]}>
          {text} {renderSpecialCharacter(specialCharacter)}
        </span>
      </span>
    </button>
  )
);

export default TextButton;

TextButton.propTypes = {
  fontSize: PropTypes.oneOf(["small", "medium", "large"]),
};
