import { useEffect, useState } from "react";
import { useAuth0JWT } from "hooks/useAuth0JWT";

const useCustomerSupportPermissions = (permissions) => {
  const decodedToken = useAuth0JWT();
  const [authorized, setAuthorized] = useState(false);

  const checkPermissions = () => {
    let hasPermission = false;
    permissions.forEach((p) => {
      if (decodedToken?.permissions.includes(p)) {
        hasPermission = true;
      }
    });
    setAuthorized(hasPermission);
  };

  useEffect(() => {
    if (!permissions || !decodedToken) return;

    checkPermissions();
  }, [permissions, decodedToken]);

  return { authorized };
};

export default useCustomerSupportPermissions;
