import React, { useState, useEffect } from "react";
import { useUsersV2 } from "stores/supportStore";
import List from "components/shared/list/List";
import NoResults from "components/shared/noresults/NoResults";
import UserList from "./users/List";
import PageLoader from "components/shared/pageloader/PageLoader";
import { useDebounce } from "hooks/useDebounce";

function Search({}) {
  const [search, setSearch] = useState("");
  const [userSearchRequest, setUserSearchRequest] = useState();
  const [showData, setShowData] = useState(false);
  const debouncedSearchTerm = useDebounce(search, 500);

  const {
    data: users,
    error,
    isLoading,
    isFetching,
  } = useUsersV2(userSearchRequest);

  useEffect(() => {
    if (debouncedSearchTerm?.trim()?.length < 3) {
      setUserSearchRequest(null);
      setShowData(false);
    } else if (
      debouncedSearchTerm?.length === 18 &&
      /^\d+$/.test(debouncedSearchTerm)
    ) {
      setUserSearchRequest({
        searchByUserProfileId: debouncedSearchTerm,
      });
      setShowData(true);
    } else {
      setUserSearchRequest({
        searchByNameAndEmail: debouncedSearchTerm,
      });
      setShowData(true);
    }
  }, [debouncedSearchTerm]);

  //Set our debouncedSearchTerm
  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className="mx-5 mb-5">
      <div className="pt-4 pb-4">
        <input
          className="input is-small"
          type="text"
          autoComplete="off"
          data-lpignore="true" //This prevents Last Pass from displaying an auto-fill for non-password and text area fields.  This should not affect login LP usage due to that being hosted on auth0
          onChange={handleSearchChange}
          placeholder="Search Email or UserProfileId"
          autoFocus
        />
      </div>
      <List hasBorder hideLastRowBorder>
        <List.Row>
          <List.Text title="Name" />
          <List.Text title="Email" />
          <List.Text title="UserProfileId" shrinkPadding />
          <List.Text title="Org Id" shrinkPadding />
        </List.Row>

        {showData && !isLoading && !isFetching && error && (
          <p className="has-text-centered has-text-danger mt-4 mb-4">{`There was an error attempting to retrieve ${search}`}</p>
        )}
        {(isLoading || isFetching) && <PageLoader />}
        {!showData && null}
        {showData &&
        (isLoading || isFetching) &&
        users?.data?.length === 0 &&
        !error ? (
          <NoResults subtitle="Your search did not match any accounts. Please try again." />
        ) : (
          <>
            {showData && !isLoading && !isFetching && !error
              ? users?.data?.map((user, index) => (
                  <UserList key={user.userProfileId} user={user} />
                ))
              : null}
          </>
        )}
      </List>
    </div>
  );
}

Search.propTypes = {};

export default Search;
