import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import Message from "components/shared/toast/Message";
import { endUserSession } from "stores/supportStore";

export default function EndUserSessionModal({ user, onCancel, onDone }) {
  const { handleSubmit, register, errors, getValues } = useForm();

  const { mutate, isLoading } = useMutation(
    () => {
      return endUserSession(`${user?.userProfile?.userProfileId}`);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success text="Successfully ended user session. Redirecting to User Search." />
        );
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit() {
    mutate();
  }

  return (
    <Modal onClose={() => onCancel()}>
      <Modal.Header title="End User Session" onClose={() => onCancel()} />
      <Modal.Body>
        <p>
          Are you sure you want to end this session with <br />
          <strong>
            {`${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`}
          </strong>
        </p>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={!user}
        isSubmitting={isLoading}
        submitText="End Session"
      />
    </Modal>
  );
}
