import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Dropdown.module.scss";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";

export default function Dropdown({
  icon,
  onClick,
  direction,
  children,
  id = "dropdown-menu-btn",
  isRelativelyPositioned,
  isTransparent,
}) {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setIsActive(!isActive);
  }

  return (
    <div
      className={cx(
        "dropdown is-right",
        {
          "is-active": isActive,
          [styles.dropdownContainerPositionRelative]: isRelativelyPositioned,
          [styles.transparent]: isTransparent,
        },
        styles.dropdownContainer
      )}
    >
      <div className="dropdown-trigger">
        <button
          className={cx("button", styles.actionBtn)}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={handleClick}
          ref={dropdownRef}
          id={id}
        >
          <span className="icon-text">
            <span className="icon">
              <span className="material-icons-outlined">{icon}</span>
            </span>
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div
          className={`dropdown-content ${styles.dropdownContent}`}
          onClick={(e) => {
            e.stopPropagation();
            handleClick();
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

function Item({ onClick, id, text, href, icon, color }) {
  function handleItemClick(e) {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  }

  return (
    <a
      href={href}
      id={id}
      className={cx(`dropdown-item ${styles.dropdownItem}`, styles[color])}
      onClick={handleItemClick}
    >
      {icon ? (
        <span className="icon-text">
          <span className="icon">
            <span className="material-icons-outlined">{icon}</span>
          </span>
          <p className="pl-2">{text}</p>
        </span>
      ) : (
        text
      )}
    </a>
  );
}

function Divider() {
  return <hr className="dropdown-divider" />;
}

Dropdown.Item = Item;
Dropdown.Divider = Divider;

Dropdown.Item.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string,
  onClick: PropTypes.func,
};

Dropdown.Item.defaultProps = {
  id: "dropdown-menu-item-btn",
  href: "#",
};

Dropdown.propTypes = {
  /**
   * What icon should be displayed on the button
   */
  icon: PropTypes.oneOf(["more_vert", "add"]),
};

Dropdown.defaultProps = {
  icon: "more_vert",
};
