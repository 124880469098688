import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import Message from "components/shared/toast/Message";
import { sendCode } from "stores/supportStore";

export default function UserSendCodeModal({ user, onCancel, onDone }) {
  const { handleSubmit, register, errors, getValues } = useForm();

  const { mutate, isLoading } = useMutation(
    () => {
      return sendCode(user?.userProfile?.userProfileId);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success text="User has been sent a code.  Please enter the code below." />
        );
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit() {
    mutate();
  }

  return (
    <Modal onClose={() => onCancel()}>
      <Modal.Header title="Send Code" onClose={() => onCancel()} />
      <Modal.Body>
        <p>
          Please confirm you would like to send a code to <br />
          <strong>
            {`${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`} (
            {user?.userProfile?.email})
          </strong>
          .
        </p>
        <p className="mt-4">
          They will receive an email from noreply@prisid.io. <br />
          On the next screen please verify the 6 digit code provided in that
          email.
        </p>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={!user}
        isSubmitting={isLoading}
        submitText="Send"
      />
    </Modal>
  );
}
