export default {
  USER_READ_PERMISSION: "cs:user:read",
  USER_VAULTS_READ_PERMISSION: "cs:user:vaults-read",
  USER_SEND_CODE_PERMISSION: "cs:user:send-code",
  USER_LOCK_PERMISSION: "cs:user:lock",
  USER_UNLOCK_PERMISSION: "cs:user:unlock",
  USER_PASSWORD_RESET_PERMISSION: "cs:user:password-reset",
  USER_VAULT_REVIVE_PERMISSION: "cs:user:vault-revive",
  USER_VAULT_DISABLE_PERMISSION: "cs:user:vault-disable",
  VAULT_STATUS_WRITE_PERMISSION: "cs:vault:status:write",
  AGENT_READ_PERMISSION: "cs:agent:read",
  AGENT_ACTIVITY_READ_PERMISSION: "cs:agent:activity:read",
};

// Reference: https://prisidio.atlassian.net/wiki/spaces/ENGINEERIN/pages/1437958147/CSP+Permissions+and+Roles
//       - value: cs:user:read
//         description: Read User Information
//       - value: cs:user:lock
//         description: Lock User
//       - value: cs:user:unlock
//         description: Unlock User
//       - value: cs:user:password-reset
//         description: Reset User Passwords
//       - value: cs:user:vaults-read
//         description: Read User's Vaults
//       - value: cs:user:send-code
//         description: Send MFA Codes, Verify Code,End Session
//       - value: cs:user:vault-disable
//         description: Disable User's Vault
//       - value: cs:user:vault-revive
//         description: Revive User's Disabled Vault
//       - value: cs:vault:status:write
//         description: Allows vault status and vault release
//       - value: cs:agent:activity:read
//         description: Read Customer Support Agent's activities
//       - value: cs:agent:read
//         description: Read all customer support agents
