import React, { useRef, useState } from "react";
import TextLink from "components/shared/link/TextLink";
import { middleEllipsis } from "util/helpers";
import Panel from "components/shared/panel/Panel";
import Dropdown from "components/shared/dropdown/Dropdown";
import styles from "./Vault.module.scss";
import VaultDataModal from "./modals/VaultDataModal";
import ReviveVaultModal from "./modals/ReviveVaultModal";
import DisableVaultModal from "./modals/DisableVaultModal";
import Button from "components/shared/button/Button";
import { useOrganization } from "stores/organizationsStore";
import PermissionOptions from "./permissions/PermissionOptions";
import wellKnownPolicyGroup from "enums/permissions/wellKnownPolicyGroup";
import { VaultRoleEnum } from "enums/permissions/vaultRole";
import { formatPhoneNumber } from "react-phone-number-input";
import { useUser } from "../../../stores/supportStore";
import ReleaseVaultModal from "./modals/ReleaseVaultModal";
import releaseStatus from "../../../enums/releaseStatus";
import { VaultStatusEnum } from "../../../enums/vaultStatus";
import CustomerSupportPermissionValidator from "components/shared/permissions/CustomerSupportPermissionValidator";
import customerSupportPermissions from "enums/permissions/customerSupportPermissions";

function Vault({ vault }) {
  const [showData, setShowData] = useState(false);
  const [showReleaseVault, setShowReleaseVault] = useState(false);
  const [revertReleaseVault, setRevertReleaseVault] = useState(false);
  const userDropdownRef = useRef(null);
  const [isVaultDisabled, setIsVaultDisabled] = useState(false);
  const [isVaultRevived, setIsVaultRevived] = useState(false);

  const { data: organization, isLoading } = useOrganization(
    vault?.vault?.orgId
  );

  const { data: vaultOwnerData } = useUser(
    vault?.vault?.vaultOwnerUserProfileId
  );

  const { data: coOwnerData } = useUser(
    vault?.vault?.vaultCoOwnerUserProfileId
  );

  // TODO update when we support multiple keyholders. This is set to one for now
  const { data: keyholderData } = useUser(
    vault?.vault?.vaultKeyHolderUserProfileIds?.[0]
  );

  const { data: custodianData } = useUser(
    vault?.vault?.vaultCustodianUserProfileId
  );

  function renderInfoSection(infoItems) {
    return (
      <>
        {infoItems?.map((info, index) => {
          return (
            <div
              className={`field pb-3 column ${
                info?.isFullCol ? "is-full" : "is-one-quarter"
              }`}
              key={`${info}${index}`}
            >
              <label className="label has-text-size-normal mb-1">
                {info.label}
              </label>
              {info?.isLink ? (
                <TextLink
                  url={info?.value}
                  text={middleEllipsis(info?.value, 30, 15, 15)}
                  id={`info-link`}
                  openNewWindow
                />
              ) : (
                <p
                  className={`has-text-karman100 has-text-weight-medium ${info?.color}`}
                >
                  {!!info.value ? info.value : "--"}
                </p>
              )}
            </div>
          );
        })}
      </>
    );
  }

  function getKeyPersonInfo(label, keyPersonData) {
    if (!keyPersonData) {
      return [
        {
          label,
          value: `No ${label} in this vault`,
        },
      ];
    }

    let phoneString = "";
    if (keyPersonData?.userProfile?.mobilePhone)
      phoneString = phoneString.concat(
        `MobilePhone:${formatPhoneNumber(
          keyPersonData?.userProfile?.mobilePhone
        )}`
      );
    if (keyPersonData?.userProfile?.homePhone)
      phoneString = phoneString.concat(
        ` | HomePhone:${formatPhoneNumber(
          keyPersonData?.userProfile?.homePhone
        )}`
      );
    if (keyPersonData?.userProfile?.workPhone)
      phoneString = phoneString.concat(
        ` | WorkPhone:${formatPhoneNumber(
          keyPersonData?.userProfile?.workPhone
        )}`
      );

    return [
      {
        label: `${label} Name`,
        value: `${keyPersonData?.userProfile?.firstName} ${keyPersonData?.userProfile?.lastName}`,
      },
      {
        label: `${label} User Profile ID`,
        value: keyPersonData?.userProfile?.userProfileId,
      },
      {
        label: `${label} Phone Numbers`,
        value: phoneString,
      },
    ];
  }

  let userInVaultInfo = [
    {
      label: "Person ID",
      value: vault?.callingPerson?.personId,
    },
    {
      label: "Person Role",
      value: vault?.callingPerson?.vaultRole,
    },
    {
      label: "Person Status",
      value: vault?.callingPerson?.personStatus,
    },
    {
      label: "Person Invite Status",
      value: vault?.callingPerson?.inviteStatus,
    },
    {
      label: "Documents Counts",
      value: vault?.assetCounts?.documentCount,
    },
    {
      label: "People Counts",
      value: vault?.assetCounts?.personCount,
    },
    {
      label: "Place Counts",
      value: vault?.assetCounts?.placeCount,
    },
    {
      label: "Things Counts",
      value: vault?.assetCounts?.thingCount,
    },
    {
      label: "Add Permissions",
      value: renderPermissions(
        vault?.policies,
        vault?.callingPerson?.vaultRole,
        wellKnownPolicyGroup.PEOPLE_ADD_ITEMS
      ),
    },
    {
      label: "Edit Permissions",
      value: renderPermissions(
        vault?.policies,
        vault?.callingPerson?.vaultRole,
        wellKnownPolicyGroup.PEOPLE_EDIT_ITEMS
      ),
    },
    {
      label: "Delete Permissions",
      value: renderPermissions(
        vault?.policies,
        vault?.callingPerson?.vaultRole,
        wellKnownPolicyGroup.PEOPLE_DELETE_ITEMS
      ),
    },
  ];

  let keyPeopleInfo = [
    ...getKeyPersonInfo("Vault Owner", vaultOwnerData?.data),
    ...getKeyPersonInfo("Co-Owner", coOwnerData?.data),
    ...getKeyPersonInfo("Keyholder", keyholderData?.data),
    ...getKeyPersonInfo("Custodian", custodianData?.data),
  ];

  let vaultInfo = [
    {
      label: "Vault ID",
      value: vault?.vault?.vaultId,
    },
    {
      label: "Vault Status",
      value: vault?.vault?.vaultStatus,
    },
    {
      label: "Release Status",
      value: vault?.vault?.releaseStatus?.status,
    },
    {
      label: "Org Id",
      value: vault?.vault?.orgId,
    },
    {
      label: "Org Name",
      value: organization?.data?.displayName,
    },
  ];

  return (
    <>
      <div className="mb-3">
        <Panel
          hasPadding
          isDanger={
            vault?.vault?.releaseStatus?.status === releaseStatus.REQUESTED ||
            vault?.vault?.releaseStatus?.status === releaseStatus.RELEASED
          }
          isWarning={
            vault?.vault?.vaultStatus === VaultStatusEnum.SUSPENDED ||
            vault?.vault?.vaultStatus === VaultStatusEnum.CANCELED ||
            vault?.vault?.vaultStatus === VaultStatusEnum.INACTIVE
          }
        >
          <h2 className="mb-3">Vault {vault?.vault?.vaultName}</h2>
          <div className="columns is-multiline mb-3">
            <div className={styles.dropdownMenu}>
              <span ref={userDropdownRef} className="is-hidden"></span>
              <Dropdown id="dropdown-user-menu-btn">
                <Dropdown.Item
                  text="View API Data"
                  icon="data_object"
                  onClick={() => {
                    setShowData(true);
                  }}
                  id="show-vault-user-btn"
                />
                <CustomerSupportPermissionValidator
                  permissions={[
                    customerSupportPermissions.VAULT_STATUS_WRITE_PERMISSION,
                  ]}
                  additionalCheck={
                    vault?.vault?.releaseStatus?.status ===
                    releaseStatus.REQUESTED
                  }
                >
                  <Dropdown.Item
                    text="Release Vault"
                    icon="new_releases"
                    onClick={() => {
                      setRevertReleaseVault(false);
                      setShowReleaseVault(true);
                    }}
                    id="show-release-vault-btn"
                  />
                </CustomerSupportPermissionValidator>
                <CustomerSupportPermissionValidator
                  permissions={[
                    customerSupportPermissions.VAULT_STATUS_WRITE_PERMISSION,
                  ]}
                  additionalCheck={
                    vault?.vault?.releaseStatus?.status ===
                    releaseStatus.RELEASED
                  }
                >
                  <Dropdown.Item
                    text="Revert Release Vault"
                    icon="new_releases"
                    onClick={() => {
                      setRevertReleaseVault(true);
                      setShowReleaseVault(true);
                    }}
                    id="show-release-vault-btn"
                  />
                </CustomerSupportPermissionValidator>
              </Dropdown>
            </div>
          </div>
          <h3 className="mb-3">Vault Info</h3>
          <br />
          <div className="columns is-multiline mb-3">
            {renderInfoSection(vaultInfo)}
          </div>
          <h3 className="mb-3">Key People in Vault</h3>
          <br />
          <div className="columns is-multiline mb-3">
            {renderInfoSection(keyPeopleInfo)}
          </div>
          <h3 className="mb-3">User in Vault Info</h3>
          <br />
          <div className="columns is-multiline mb-3">
            {renderInfoSection(userInVaultInfo)}
          </div>
          <div className="is-flex is-justify-content-flex-end">
            <div className="mb-3">
              <CustomerSupportPermissionValidator
                permissions={[
                  customerSupportPermissions.USER_VAULT_DISABLE_PERMISSION,
                ]}
                additionalCheck={
                  !isVaultDisabled && vault?.vault?.vaultStatus === "REVIVED"
                }
              >
                <Button
                  text="Disable Vault"
                  id="disable-vault-btn"
                  icon={null}
                  color="secondary"
                  onClick={() => {
                    setIsVaultDisabled(true);
                    setIsVaultRevived(false);
                  }}
                />
              </CustomerSupportPermissionValidator>
              <CustomerSupportPermissionValidator
                permissions={[
                  customerSupportPermissions.USER_VAULT_REVIVE_PERMISSION,
                ]}
                additionalCheck={
                  !isVaultRevived && vault?.vault?.vaultStatus === "DISABLED"
                }
              >
                <Button
                  text="Revive Vault"
                  id="revive-vault-btn"
                  icon={null}
                  color="secondary"
                  onClick={() => {
                    setIsVaultDisabled(false);
                    setIsVaultRevived(true);
                  }}
                />
              </CustomerSupportPermissionValidator>
              {isVaultDisabled && (
                <DisableVaultModal
                  onCancel={() => setIsVaultDisabled(false)}
                  onDone={() => setIsVaultDisabled(false)}
                  vault={vault}
                />
              )}
              {isVaultRevived && (
                <ReviveVaultModal
                  onCancel={() => setIsVaultRevived(false)}
                  onDone={() => setIsVaultRevived(false)}
                  vault={vault}
                />
              )}
            </div>
          </div>
        </Panel>
      </div>
      {showData && (
        <VaultDataModal vault={vault} onCancel={() => setShowData(false)} />
      )}
      {showReleaseVault && (
        <ReleaseVaultModal
          vault={vault}
          revertRelease={revertReleaseVault}
          onCancel={() => setShowReleaseVault(false)}
        />
      )}
    </>
  );
}

function renderPermissions(data, vaultRole, wellKnownPolicyGroup) {
  const personIsOwner = vaultRole === VaultRoleEnum.ROLE_VAULT_OWNER;
  const personIsCoOwner = vaultRole === VaultRoleEnum.ROLE_VAULT_CO_OWNER;

  return (
    <>
      <PermissionOptions
        data={data}
        personIsOwner={personIsOwner}
        personIsCoOwner={personIsCoOwner}
        wellKnownPolicyGroup={wellKnownPolicyGroup}
      />
    </>
  );
}

Vault.propTypes = {};

export default Vault;
