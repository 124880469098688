import React, { useState } from "react";
import PropTypes from "prop-types";
import Panel from "components/shared/panel/Panel";
import Info from "./info/Info";
import TextButton from "components/shared/button/TextButton";
import styles from "./UserAccountInfo.module.scss";
import UserUnlockModal from "components/user/modals/UserUnlockModal";

function AccountStatus({ user }) {
  const userAccountStatus = [
    {
      label: "Status",
      value: user?.userProfile?.userStatus,
      color:
        user?.userProfile?.userStatus === "ACTIVE"
          ? "has-text-success"
          : "has-text-danger",
    },
    { label: "Locked Date", value: user?.userProfile?.lockedDate },
    { label: "Lock Reason", value: user?.userProfile?.lockedReason },
  ];
  const [isUnlockingUser, setIsUnlockingUser] = useState(false);

  return (
    <Panel hasPadding>
      <h3 className="mb-3">Account Status</h3>
      <Info infoArray={userAccountStatus} />
      {user?.userProfile?.userStatus !== "ACTIVE" && (
        <div className={styles.infoButton}>
          <TextButton
            id="media-add-btn"
            onClick={() => {
              setIsUnlockingUser(true);
            }}
            icon={null}
            text="Unlock"
          />
        </div>
      )}
      {isUnlockingUser && (
        <UserUnlockModal
          user={user}
          onCancel={() => setIsUnlockingUser(false)}
          onDone={() => setIsUnlockingUser(false)}
        />
      )}
    </Panel>
  );
}

AccountStatus.propTypes = {};

export default AccountStatus;
