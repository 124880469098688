import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Panel from "components/shared/panel/Panel";
import List from "components/shared/list/List";
import Info from "./info/Info";
import TextButton from "components/shared/button/TextButton";
import styles from "./UserAccountInfo.module.scss";
import { getActivityDetail } from "util/activityBuilder";
import moment from "moment";
import { activityBelongsToFilter } from "util/activityFilter";
import activityTypes from "enums/activityTypes";
import TextLink from "components/shared/link/TextLink";
import UserResetPasswordModal from "../modals/UserResetPasswordModal";
import CustomerSupportPermissionValidator from "components/shared/permissions/CustomerSupportPermissionValidator";
import customerSupportPermissions from "enums/permissions/customerSupportPermissions";

function Login({ user, activities }) {
  const [lastWebLogin, setLastWebLogin] = useState();
  const [lastMobileLogin, setLastMobileLogin] = useState();
  const [lastBiometricLogin, setLastBiometricLogin] = useState();
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const [filteredActivities, setFilteredActivities] = useState(activities);
  const [filterDateBy, setFilterDateBy] = useState(null);

  function filterByDate(activity) {
    let f = moment().subtract(filterDateBy, "days").unix();
    let ts = moment(parseInt(activity?.timestamp)).unix();
    if (filterDateBy === null) return true;
    return ts > f;
  }

  useMemo(
    () =>
      setFilteredActivities(
        activities
          ?.filter(filterByDate)
          ?.filter((activity) =>
            activityBelongsToFilter(activity, activityTypes.SIGNIN)
          )
      ),
    [activities]
  );

  useMemo(
    () =>
      setLastMobileLogin(
        filteredActivities?.filter(
          (activity) =>
            activity?.data?.clientName === "Prisidio Android Application" ||
            activity?.data?.clientName === "Prisidio iOS Application" ||
            activity?.data?.activityType === "BIOMETRIC_LOGIN_SUCCESSFUL"
        )[0]?.timestamp
      ),
    [filteredActivities]
  );

  useMemo(
    () =>
      setLastWebLogin(
        filteredActivities?.filter(
          (activity) =>
            activity?.data?.clientName === "Prisidio Web Application"
        )[0]?.timestamp
      ),
    [filteredActivities]
  );

  useMemo(
    () =>
      setLastBiometricLogin(
        filteredActivities?.filter(
          (activity) =>
            activity?.data?.activityType === "BIOMETRIC_LOGIN_SUCCESSFUL"
        )[0]?.timestamp
      ),
    [filteredActivities]
  );

  function getFormattedDate(date) {
    if (!date) return null;

    return moment(parseInt(date)).format("M/D/YYYY @ h:mm A");
  }

  const lastLoginInfo = [
    {
      label: "Last Web Login",
      value: getFormattedDate(lastWebLogin),
    },
    {
      label: "Last Mobile Login",
      value: getFormattedDate(lastMobileLogin),
    },
    {
      label: "Last Biometric Login",
      value: getFormattedDate(lastBiometricLogin),
    },
  ];

  return (
    <>
      <Panel>
        <h3 className="m-3">Log In</h3>
        <div className="ml-3">
          <Info infoArray={lastLoginInfo} />
        </div>
        <List>
          {filteredActivities?.slice(0, 5)?.map((activity, index) => {
            const activityDetail = getActivityDetail(activity, true);
            return (
              <List.Row key={activity.activityId}>
                <List.Text
                  title={activityDetail.header}
                  subtitle={activityDetail.action}
                />
                <span className={styles.date}>{activityDetail.date}</span>
              </List.Row>
            );
          })}
        </List>
        <CustomerSupportPermissionValidator
          permissions={[
            customerSupportPermissions.USER_PASSWORD_RESET_PERMISSION,
          ]}
        >
          <div className={styles.infoButton}>
            <TextButton
              id="reset-password-btn"
              onClick={() => {
                setIsResettingPassword(true);
              }}
              text="Reset Password"
              icon={null}
            />
          </div>
        </CustomerSupportPermissionValidator>

        <div className="m-3">
          <TextLink
            url={`/users/${user?.userProfile?.userProfileId}/activity`}
            text="See All Activities"
            hasChevron
          />
        </div>
      </Panel>
      {isResettingPassword && (
        <UserResetPasswordModal
          user={user}
          onCancel={() => setIsResettingPassword(false)}
          onDone={() => setIsResettingPassword(false)}
        />
      )}
    </>
  );
}

Login.propTypes = {};

export default Login;
