import React from "react";
import PropTypes from "prop-types";
import Panel from "components/shared/panel/Panel";
import Info from "./info/Info";
import moment from "moment";

function BillingInfo({ user }) {
  let cost = (user?.vaultSubscriptions[0]?.subscriptionPrice / 100).toFixed(2);
  let formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let planCost = formatter.format(cost);

  const userBillingInfo = [
    {
      label: "Stripe Account",
      value: user?.vaultSubscriptions[0]?.paymentProvider?.link,
      isFullCol: true,
      isLink: true,
    },
    {
      label: "Plan / Subscription ID",
      isFullCol: true,
      value: user?.vaultSubscriptions[0]?.productName,
    },
    { label: "Promo Code", value: user?.vaultSubscriptions[0]?.promoCode },
    { label: "Plan Cost", value: planCost },
    {
      label: "Last 4",
      value: user?.vaultSubscriptions[0]?.paymentMethod?.last4,
    },
    {
      label: "Previous Payment",
      value: moment
        .unix(user?.vaultSubscriptions[0]?.lastPayment?.date)
        .format("llll"),
    },
    {
      label: "Next Payment",
      value: user?.vaultSubscriptions[0]?.nextPayment?.date
        ? moment
            .unix(user?.vaultSubscriptions[0]?.nextPayment?.date)
            .format("llll")
        : null,
    },
    { label: "Expiration Date", value: user?.vaultSubscriptions[0]?.defaultCardExpirationDate },
    { label: "Billing ZipCode", value: user?.vaultSubscriptions[0]?.defaultPaymentBillingZip },
  ];

  return (
    <Panel hasPadding>
      <h3 className="mb-3">Billing Info</h3>
      <Info infoArray={userBillingInfo} />
    </Panel>
  );
}

BillingInfo.propTypes = {};

export default BillingInfo;
