import React from "react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Users from "components/users/Users";

export function UsersPage({}) {
  return <Users />;
}

export default withAuthenticationRequired(UsersPage, {
  onRedirecting: () => <PageLoader />,
});
