import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./Avatar.module.scss";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import CircleIcon from "../icon/CircleIcon";
import PageLoader from "../pageloader/PageLoader";

const colors = [
  "#00C67A",
  "#00B1D1",
  "#9B63FD",
  "#F06D60",
  "#EE9944",
  "#FF37BE",
];

const defaultColor = "#FBFBFC";

function getNameInitials(name) {
  return (
    name &&
    name
      .match(/\b(\w)/g)
      ?.join("")
      ?.substring(0, 2)
  );
}

function numberFromName(name) {
  const charCodes = getNameInitials(name)
    .split("")
    .map((char) => char.charCodeAt(0))
    .join("");
  return parseInt(charCodes, 10);
}

export default function Avatar({
  src,
  name,
  size = "medium",
  showTooltip = false,
  isClickable = false,
  hasBorder,
  children,
  onClick,
  isLoading,
}) {
  const [showChildren, setShowChildren] = useState(false);

  return isLoading ? (
    <CircleIcon size={size}>
      <PageLoader />
    </CircleIcon>
  ) : (
    <div
      className={cx(styles.avatar, { "is-clickable": isClickable })}
      onClick={
        isClickable && onClick
          ? () => onClick()
          : () => {
              return;
            }
      }
    >
      {src ? (
        <span
          className={cx(styles.avatarImage, styles[size])}
          style={{ backgroundImage: `url(${src})` }}
        ></span>
      ) : (
        <div
          className={cx(styles.initials, styles[size], {
            "is-clickable": isClickable,
            [styles.hasBorder]: hasBorder,
          })}
          style={
            getNameInitials(name)
              ? {
                  backgroundColor: colors[numberFromName(name) % colors.length],
                }
              : { backgroundColor: defaultColor }
          }
        >
          {!getNameInitials(name) && (
            <span
              className={`material-icons-outlined ${cx(
                styles.icon,
                styles[`icon-${size}`]
              )}`}
            >
              person_outline
            </span>
          )}
          {getNameInitials(name) && getNameInitials(name)}
          {showTooltip && <span className={styles.tooltipName}>{name}</span>}
        </div>
      )}
      {children}
    </div>
  );
}

function ChildIcon({ icon = "camera_alt" }) {
  return (
    <span className={cx("icon", styles.childIconWrapper)}>
      <span className={cx("material-icons-outlined", styles.childIcon)}>
        {icon}
      </span>
    </span>
  );
}

function Dropdown({
  children,
  isActive,
  outsideClickRef,
  direction = "right",
}) {
  return (
    <div
      className={cx(
        "navbar-link is-arrowless is-hidden-touch dropdown is-left",
        { "is-active": isActive },
        styles.dropdownContainer,
        styles[direction]
      )}
      id="navbar-help-btn"
      ref={outsideClickRef}
    >
      <div
        className={cx("dropdown-menu", styles.dropdownMenu)}
        id="help-menu"
        role="menu"
      >
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
}

function DropdownItem({ icon, title, onClick, id, isDanger }) {
  return (
    <div className="dropdown-item" onClick={onClick} id={id}>
      <div className={styles.dropdownItem}>
        <span
          className={cx("material-icons-outlined", styles.menuIcon, {
            [styles.danger]: isDanger,
          })}
        >
          {icon}
        </span>
        <span
          className={cx(styles.menuItemText, { [styles.danger]: isDanger })}
        >
          {title}
        </span>
      </div>
    </div>
  );
}

Avatar.ChildIcon = ChildIcon;

Avatar.Dropdown = Dropdown;

Avatar.DropdownItem = DropdownItem;

Avatar.propTypes = {
  /**
   * Image URL of the user you want to display
   */
  src: PropTypes.string,
  /**
   * First and Last name. Alt text used for screen readers and in the case of the user image not being found
   */
  name: PropTypes.string.isRequired,
  /**
   * How large should the avatar be?
   */
  size: PropTypes.oneOf(["small", "medium", "large", "xlarge", "xxlarge"]),
  /**
   * Display the name as a tooltip when the avatar does not have an image
   */
  showTooltip: PropTypes.bool,
};
