import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./NoContent.module.scss";
import Button from "../button/Button";

export default function NoContent({ title, subtitle, children }) {
  return (
    <div className={`${styles.noContent}`} id="no-content-container">
      <h1 id="no-content-title" className="mb-3">
        {title}
      </h1>
      <p id="no-content-subtitle">{subtitle}</p>
      {children}
    </div>
  );
}

function Video({ url }) {
  return (
    <video
      className={styles.video}
      poster="/placeholder.png"
      playsInline
      preload="none"
      aria-hidden={true}
      controls={false}
      //src="https://www.apple.com/105/media/us/iphone-13/2021/19e0ebb3-0e17-4765-95af-c82bc827e02f/anim/hero/large.mp4"
    ></video>
  );
}

function Items({ children }) {
  return <div className={styles.items}>{children}</div>;
}

function Item({ text, onClick }) {
  return (
    <Button
      id="no-content-item-btn"
      color="secondary"
      hasSecondaryMargin
      text={text}
      icon="add"
      onClick={onClick}
    />
  );
}

NoContent.Video = Video;
NoContent.Items = Items;
NoContent.Item = Item;

NoContent.propTypes = {
  /**
   * NoContent title
   */
  title: PropTypes.string.isRequired,
  /**
   * NoContent subtitle
   */
  subtitle: PropTypes.string.isRequired,
};

NoContent.defaultProps = {
  title: "No Items",
  subtitle: "Any items that are added will be displayed here.",
};
