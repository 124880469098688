export default {
  PERSON_ADD_ANYTHING: "person-add-anything",
  PERSON_ADD_LIMITED: "person-add-limited",
  PERSON_EDIT_ANYTHING: "person-edit-anything",
  PERSON_EDIT_LIMITED: "person-edit-limited",
  PERSON_DELETE_ANYTHING: "person-delete-anything",
  PERSON_DELETE_LIMITED: "person-delete-limited",
  ROLE_OWNER: "role-owner",
  ROLE_CO_OWNER: "role-co-owner",
  ROLE_INVITED_USER: "role-invited-user",
};
