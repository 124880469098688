import React from "react";
import Panel from "components/shared/panel/Panel";
import List from "components/shared/list/List";
import Icon from "components/shared/icon/Icon";
import TextLink from "components/shared/link/TextLink";
import history from "utils/history";

export default function DashboardLinks({ title, links }) {
  return (
    <>
      <Panel hasPadding>
        <Panel.Title text={title} />
        <List>
          {links.map((link) => {
            return (
              <List.Row key={link.name} onClick={() => window.open(link.url)}>
                <List.Text title={link.name} subtitle={link.description} />
                <List.Item>
                  <Icon type="open_in_new"></Icon>
                </List.Item>
              </List.Row>
            );
          })}
        </List>
        <Panel.Footer />
      </Panel>
    </>
  );
}

DashboardLinks.propTypes = {};
