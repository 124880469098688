import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import Message from "components/shared/toast/Message";
import { unlockUser } from "stores/supportStore";

export default function UserUnlockModal({ user, onCancel, onDone }) {
  const { handleSubmit, register, errors, getValues } = useForm();

  const { mutate, isLoading } = useMutation(
    () => {
      return unlockUser(user?.userProfile?.userProfileId);
    },
    {
      onSuccess: (response) => {
        toast.success(<Message.Success text="User has been unlocked." />);
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit() {
    mutate();
  }

  return (
    <Modal onClose={() => onCancel()}>
      <Modal.Header title="Unlock User" onClose={() => onCancel()} />
      <Modal.Body>
        <p>
          Please confirm you'd like to unlock the account of
          <br />
          <strong>
            {`${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`} (
            {user?.userProfile?.email})
          </strong>
        </p>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={!user}
        isSubmitting={isLoading}
        submitText="Confirm"
      />
    </Modal>
  );
}
