import React from "react";
import PageLoader from "components/shared/pageloader/PageLoader";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Agents from "components/agents/Agents";

export function AgentsPage({}) {
  return <Agents />;
}

export default withAuthenticationRequired(AgentsPage, {
  onRedirecting: () => <PageLoader />,
});
