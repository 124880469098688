import React from "react";
import styles from "./NoResults.module.scss";
import cx from "classnames";

export default function NoResults({
  title = "No matching results",
  subtitle = "Your search did not match any items in your vault. Please try again.",
  size,
  displayBlock,
  children,
}) {
  return (
    <div
      className={cx(styles.noActivity, styles[size], {
        [styles.displayBlock]: displayBlock,
      })}
      id="no-content-container"
    >
      {children ? (
        children
      ) : (
        <>
          <h1 id="no-content-title" className="mb-3">
            {title}
          </h1>
          <p>{subtitle}</p>
        </>
      )}
    </div>
  );
}
