import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import Message from "components/shared/toast/Message";
import { verifyCode } from "stores/supportStore";
import FormField from "components/shared/formfield/FormField";

export default function UserVerifyCodeModal({ user, onCancel, onDone }) {
  const { handleSubmit, register, errors, getValues } = useForm();

  const { mutate, isLoading } = useMutation(
    (code) => {
      return verifyCode(user?.userProfile?.userProfileId, code);
    },
    {
      onSuccess: (response) => {
        toast.success(<Message.Success text="Code entry successful." />);
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit(data) {
    mutate(data.code);
  }

  return (
    <Modal onClose={() => onCancel()}>
      <Modal.Header title="Verify Code" onClose={() => onCancel()} />
      <Modal.Body>
        <p>
          Please enter the verification code sent to <br />
          <strong>
            {`${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`} (
            {user?.userProfile?.email})
          </strong>
        </p>
        <form className="mt-4">
          <FormField
            name="code"
            type="text"
            placeholder="Verification Code"
            size="normal"
            id="verification-code"
            error={errors.code}
            inputRef={register({
              required: "Please enter a verification code",
            })}
          />
        </form>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={!user}
        isSubmitting={isLoading}
        submitText="Confirm"
      />
    </Modal>
  );
}
