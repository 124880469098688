import { useCallback, useEffect, useRef } from "react";

const useInfiniteScroll = ({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  scrollableTargetRef,
}) => {
  // Ref to store the last scroll position
  const lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    const targetElement =
      scrollableTargetRef?.current || document.documentElement;
    const windowHeight = window.innerHeight;
    const documentHeight = targetElement.scrollHeight;
    const scrollTop =
      targetElement === document.documentElement
        ? window.scrollY || document.documentElement.scrollTop
        : targetElement.scrollTop;

    // Calculate the scroll distance
    const scrollDistance = Math.abs(scrollTop - lastScrollTop.current);

    // Update lastScrollTop if the scroll distance is more than 100 pixels
    if (scrollDistance >= 100) {
      lastScrollTop.current = scrollTop;

      // Check if the scroll is within 1/3 of the bottom of the document
      const isCloseToBottom =
        documentHeight - scrollTop - windowHeight < documentHeight / 3;

      // Ensure fetchNextPage() is not called if isFetchingNextPage is true
      if (isCloseToBottom && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage, scrollableTargetRef]);
  useEffect(() => {
    const target = scrollableTargetRef?.current || window;
    target.addEventListener("scroll", handleScroll);
    return () => target.removeEventListener("scroll", handleScroll);
  }, [handleScroll, scrollableTargetRef]);
};

export default useInfiniteScroll;
