import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink, useLocation } from "util/router";
import styles from "./Tabs.module.scss";

export default function Tabs({
  children,
  isCentered,
  isFullWidth,
  hasBottomBorder,
}) {
  return (
    <div
      className={cx(
        "tabs",
        { "is-centered": isCentered },
        { "is-fullwidth": isFullWidth },
        { "has-bottom-border-gray": hasBottomBorder }
      )}
    >
      <ul>{children}</ul>
    </div>
  );
}

function getActiveLinkClass(location, path) {
  return location.pathname === path ? "is-active" : "";
}

function getActiveSpanClass(location, path) {
  return location.pathname === path ? styles.isActive : "";
}

function Item({ url, text, isWide, isClickable = true }) {
  const location = useLocation();

  return (
    <li className={getActiveLinkClass(location, url)}>
      {!isClickable ? (
        <span
          className={cx(getActiveSpanClass(location, url), styles.tabItem, {
            "is-wide": isWide,
          })}
        >
          {text}
        </span>
      ) : (
        <NavLink
          to={url}
          className={cx({ "is-wide": isWide })}
          activeClassName="is-active"
        >
          {text}
        </NavLink>
      )}
    </li>
  );
}

function WizardItem({ url, text, step, isWide, isComplete, isTodo }) {
  const location = useLocation();

  return (
    <li className={cx(getActiveLinkClass(location, url))}>
      <span
        className={cx(
          getActiveSpanClass(location, url),
          styles.tabItem,
          styles.wizardItem,
          {
            "is-wide": isWide,
            [styles.isComplete]: isComplete,
          }
        )}
      >
        <span className={styles.step}>{step}</span>
        {text}
      </span>
    </li>
  );
}

Tabs.Item = Item;
Tabs.WizardItem = WizardItem;

Tabs.propTypes = {
  /**
   * The set of Tab.Items to display
   */
  children: PropTypes.node,
  /**
   * Center the Tab.Items
   */
  isCentered: PropTypes.bool,
};

Tabs.Item.propTypes = {
  /**
   * The url of the Tab link
   */
  url: PropTypes.string.isRequired,
  /**
   * The text to display on the Tab link
   */
  text: PropTypes.string.isRequired,
  /**
   * If the Tab link is wide or not
   */
  isWide: PropTypes.bool,
};

Tabs.WizardItem.propTypes = {
  /**
   * The url of the Tab link
   */
  url: PropTypes.string.isRequired,
  /**
   * The text to display on the Tab link
   */
  text: PropTypes.string.isRequired,
  /**
   * Is this step currently active
   */
  isActive: PropTypes.bool.isRequired,
  /**
   * Is this step complete or not
   */
  isComplete: PropTypes.bool.isRequired,
  /**
   * Is this step completely disabled from clicking
   */
  disabled: PropTypes.bool.isRequired,
};

Tabs.WizardItem.defaultProps = {
  isComplete: false,
  isActive: true,
  disabled: false,
};
