import React, { useState, useEffect } from "react";
import Modal from "components/shared/modal/Modal";
import { useMutation } from "react-query";
import { releaseVault, useUser } from "../../../../stores/supportStore";
import { toast } from "react-toastify";
import Message from "../../../shared/toast/Message";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import FormField from "../../../shared/formfield/FormField";

function ReleaseVaultModal({ vault, onCancel, revertRelease }) {
  const [description, setDescription] = useState("");
  const { requesterUserProfileId } = vault?.vault?.releaseStatus;

  const { handleSubmit, register, watch, errors } = useForm();
  const { data: potentialCustodianUserProfile } = useUser(
    requesterUserProfileId
  );

  const { mutate, isLoading } = useMutation(
    () => {
      return releaseVault(vault?.vault?.vaultId, revertRelease);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success
            text={
              revertRelease ? "Vault released reverted." : "Vault released."
            }
          />
        );
        onCancel();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit() {
    mutate();
  }

  function validateVaultID(e) {
    return !!e && e === vault?.vault?.vaultId ? true : "Incorrect Vault ID";
  }

  function validateHubspotTicket(e) {
    return !!e && e?.length === 10 ? true : "Invalid Hubspot Ticket";
  }

  function validateRequestorUserProfileID(e) {
    return !!e && e === requesterUserProfileId
      ? true
      : "Incorrect Requestor User Profile ID";
  }

  useEffect(() => {
    if (revertRelease) {
      setDescription(
        "Are you sure you want to revert the release of this vault named"
      );
    } else {
      setDescription("Are you sure you want to release this vault named");
    }
  }, [revertRelease]);

  return (
    <Modal onClose={onCancel} isLarge={!revertRelease}>
      <Modal.Header title="Release Vault" onClose={onCancel} />
      <Modal.Body hasScroll>
        <p>Note: This requires the CustomerServiceManager role</p>
        <br />
        <br />
        <p>
          {description} <strong>{vault?.vault?.vaultName} </strong>
          {!revertRelease && (
            <>
              to{" "}
              <strong>
                {potentialCustodianUserProfile?.data?.userProfile?.firstName}{" "}
                {potentialCustodianUserProfile?.data?.userProfile?.lastName}
              </strong>
            </>
          )}
          ?
        </p>
        {!revertRelease && (
          <>
            <br />
            <br />
            <h3>Vault ID</h3>

            <FormField
              name="requestedVaultId"
              type="text"
              placeholder="Enter Vault ID"
              error={errors?.requestedVaultId}
              size="normal"
              id="vaultrelease-modal-vaultid"
              inputRef={register({
                required: "Vault ID is required",
                validate: validateVaultID,
              })}
            />
            <h3>Requestor User Profile ID</h3>
            <FormField
              name="requestorUserProfileId"
              type="text"
              placeholder="Enter User Profile ID"
              error={errors?.requestorUserProfileId}
              size="normal"
              id="vaultrelease-modal-userprofileid"
              inputRef={register({
                required: "User Profile ID is required",
                validate: validateRequestorUserProfileID,
              })}
            />
            <h3>Hubspot Ticket</h3>
            <FormField
              name="hubspotTicket"
              type="text"
              placeholder="Enter Hubspot Ticket"
              error={errors?.hubspotTicket}
              size="normal"
              id="vaultrelease-modal-hubspot"
              inputRef={register({
                required: "Hubspot Ticket is required",
                validate: validateHubspotTicket,
              })}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        onCancel={onCancel}
        cancelText="Close"
        submitText="Release"
        submitColor="danger"
        onSubmit={handleSubmit(onSubmit)}
        isSubmitting={isLoading}
      />
    </Modal>
  );
}

ReleaseVaultModal.propTypes = {
  vault: PropTypes.any,
  onCancel: PropTypes.func,
};

export default ReleaseVaultModal;
