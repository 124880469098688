import React, { useState, useEffect } from "react";
import cx from "classnames";
import styles from "./FormField.module.scss";

function FormField(props) {
  const {
    error,
    type,
    size,
    inputRef,
    hasMargin = true,
    reset,
    handlePasswordChange,
    hasFixedSize = false,
    lpIgnore,
    ...inputProps
  } = props;
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayTextClear, setDisplayTextClear] = useState(false);

  function updateInputValue(e) {
    setDisplayTextClear(!!e.target.value);
  }

  function resetText() {
    //TODO - pass in reset={() => resetField('fieldName')} after upgrading to react-hook-form v7.
    //https://react-hook-form.com/api/useform/resetfield
    setDisplayTextClear(false);
    reset();
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
        event.preventDefault();
    }
  }

  return (
    <div className={cx("field", { [styles.fieldMargin]: hasMargin })}>
      {props.label && (
        <label className="label has-text-size-normal" htmlFor={props.id}>
          {props.label}
        </label>
      )}

      <div className="control">
        {type === "textarea" && (
          <textarea
            className={cx(`textarea is-${size}`, {
              "is-danger": error,
              "has-fixed-size": hasFixedSize,
              "max-height": "500px",
            })}
            ref={inputRef}
            {...inputProps}
          />
        )}

        {type === "password" && (
          <>
            <input
              className={cx(`input is-${size}`, { "is-danger": error })}
              ref={inputRef}
              type={displayPassword ? "text" : "password"}
              autoComplete="off"
              data-lpignore={lpIgnore}
              onChange={(e) =>
                handlePasswordChange && handlePasswordChange(e.target.value)
              }
              {...inputProps}
            />
            <button
              type="button"
              className={`button is-inverted ${styles.formFieldPasswordButton}`}
              onClick={() => {
                setDisplayPassword(!displayPassword);
              }}
            >
              <span className="icon">
                <span className="material-icons-outlined">
                  {displayPassword ? "visibility" : "visibility_off"}
                </span>
              </span>
            </button>
          </>
        )}

        {type !== "textarea" &&
          type !== "textareaautosize" &&
          type !== "password" && (
            <>
              <div className={styles.inputWrapper}>
                <input
                  className={cx(`input is-${size}`, { "is-danger": error })}
                  ref={inputRef}
                  type={type}
                  autoComplete="off"
                  data-lpignore="true" //This prevents Last Pass from displaying an auto-fill for non-password and text area fields.  This should not affect login LP usage due to that being hosted on auth0
                  onChange={updateInputValue}
                  onKeyDown={handleKeyDown}
                  {...inputProps}
                />
                {displayTextClear && reset && (
                  <div className={styles.removeText} onClick={resetText}>
                    <span className="icon">
                      <span className="material-icons-outlined">close</span>
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
      </div>
      <div className={styles.belowInput}>
        {props.description && (
          <span
            className={cx(
              "input-description",
              error ? styles.errorDescription : styles.description
            )}
          >
            {props.description}
          </span>
        )}

        {props.actionIcon && props.actionIconPress && (
          <span
            className={cx("icon", styles.iconWrapper)}
            onClick={props.actionIconPress}
          >
            <span className={cx("material-icons-outlined", styles.actionIcon)}>
              {props.actionIcon}
            </span>
          </span>
        )}
      </div>

      {error && <p className="help is-danger">{error.message}</p>}
    </div>
  );
}

export default FormField;
