import React, { useState } from "react";
import Icon from "components/shared/icon/Icon";
import cx from "classnames";

export default function CopyToClipboard({
  textToCopy,
  size = "small",
  icon = "content_copy",
  style = { position: "relative" },
}) {
  const [currentIcon, setCurrentIcon] = useState(icon);

  function handleClick() {
    if (currentIcon === "thumb_up") return;

    navigator.clipboard.writeText(textToCopy);
    setCurrentIcon("thumb_up");

    setTimeout(() => {
      setCurrentIcon(icon);
    }, 1000);
  }

  return (
    <span
      style={style}
      className={cx("ml-1", {
        "is-clickable": currentIcon === icon,
      })}
      onClick={handleClick}
    >
      <span style={{ position: "absolute", top: -12 }}>
        <Icon type={currentIcon} size={size} />
      </span>
    </span>
  );
}
