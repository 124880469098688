import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PermissionPolicy from "components/user/vaults/permissions/PermissionPolicy";
import permissionsConstants from "enums/permissions/permissionsConstants";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import styles from "components/user/vaults/permissions/Permissions.module.scss";
import PageLoader from "components/shared/pageloader/PageLoader";
import NoContent from "components/shared/nocontent/NoContent";
import wellKnownPolicy from "enums/permissions/wellKnownPolicy";
import wellKnownPolicyGroup from "enums/permissions/wellKnownPolicyGroup";

const ownerPolicies = [
  wellKnownPolicy.PERSON_DELETE_ANYTHING,
  wellKnownPolicy.PERSON_EDIT_ANYTHING,
  wellKnownPolicy.PERSON_ADD_ANYTHING,
];

const addPolicies = [
  wellKnownPolicy.PERSON_ADD_LIMITED,
  wellKnownPolicy.PERSON_ADD_ANYTHING,
];

const editPolicies = [
  wellKnownPolicy.PERSON_EDIT_LIMITED,
  wellKnownPolicy.PERSON_EDIT_ANYTHING,
];

const deletePolicies = [
  wellKnownPolicy.PERSON_DELETE_LIMITED,
  wellKnownPolicy.PERSON_DELETE_ANYTHING,
];

const PermissionPolicyGroup = ({
  data,
  policyGroup,
  personIsOwner,
  personIsCoOwner,
}) => {
  const [thisPolicyGroup, setThisPolicyGroup] = useState({});
  const [currentSelectedPolicy, setCurrentSelectedPolicy] = useState(null);

  const personHasOwnerAccess = personIsOwner || personIsCoOwner;

  useEffect(() => {
    const foundPolicyGroup = data?.policyGroups?.find(
      (e) => e.wellKnownPolicyGroup === policyGroup
    );
    const attachedPolicy = foundPolicyGroup?.policies?.find((r) => r.attached);

    setThisPolicyGroup(foundPolicyGroup);
    setCurrentSelectedPolicy(attachedPolicy);

  }, [data?.policyGroups]);

  function renderThisPolicyGroup() {
    if (thisPolicyGroup) {
      if(personHasOwnerAccess) {
        const ownerPolicy = thisPolicyGroup?.policies?.find((policy) => ownerPolicies.includes(policy?.wellKnownPolicy));
        return (
            <PermissionPolicy description={permissionsConstants.DESCRIPTIONS[ownerPolicy?.wellKnownPolicy]} />
        );
      } else {
        var description = permissionsConstants.DESCRIPTIONS[thisPolicyGroup?.wellKnownPolicy];

        if(description == null) {
          if(thisPolicyGroup?.wellKnownPolicyGroup === wellKnownPolicyGroup.PEOPLE_ADD_ITEMS) {
            const addPolicy = addPolicies.includes(currentSelectedPolicy?.wellKnownPolicy);
            if(!addPolicy) {
              description = "Cannot add";
            }
          } else if (thisPolicyGroup?.wellKnownPolicyGroup === wellKnownPolicyGroup.PEOPLE_EDIT_ITEMS) {
            const editPolicy = editPolicies.includes(currentSelectedPolicy?.wellKnownPolicy);
            if(!editPolicy) {
              description = "Cannot edit";
            }
          } else if (thisPolicyGroup?.wellKnownPolicyGroup === wellKnownPolicyGroup.PEOPLE_DELETE_ITEMS) {
            const deletePolicy = deletePolicies.includes(currentSelectedPolicy?.wellKnownPolicy);
            if(!deletePolicy) {
              description = "Cannot delete";
            }
          }
          if(description == null) {
            description = permissionsConstants.DESCRIPTIONS[currentSelectedPolicy?.wellKnownPolicy];
          }
        }
        return ( <PermissionPolicy description={description} />);
      }
    }
  }

  return (
    renderThisPolicyGroup()
  );
};

PermissionPolicyGroup.propTypes = {
  data: PropTypes.array,
  policyGroup: PropTypes.string,
};

export default PermissionPolicyGroup;
