import React from "react";
import PropTypes from "prop-types";
import Modal from "components/shared/modal/Modal";

function UserDataModal({ user, onCancel }) {
  return (
    <Modal onClose={() => onCancel()} isLarge>
      <Modal.Header title="User API Data" onClose={() => onCancel()} />
      <Modal.Body hasScroll>
        <pre
          style={{
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            margin: "8px 0 0 0",
          }}
        >
          {JSON.stringify(user?.userProfile, null, "\t")}
        </pre>
        <pre
          style={{
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            margin: "8px 0 0 0",
          }}
        >
          {JSON.stringify(user?.vaultSubscriptions, null, "\t")}
        </pre>
      </Modal.Body>
      <Modal.Footer onCancel={() => onCancel()} cancelText="Close" hideSubmit />
    </Modal>
  );
}

UserDataModal.propTypes = {};

export default UserDataModal;
