import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import Message from "components/shared/toast/Message";
import { reviveVault } from "stores/supportStore";
import FormField from "components/shared/formfield/FormField";
import moment from "moment";

export default function ReviveVaultModal({ user, vault, onCancel, onDone }) {
  const { handleSubmit, register, errors, getValues, reset } = useForm();

  const { mutate, isLoading } = useMutation(
    (vaultRevivalInfo) => {
      return reviveVault(vault?.vault?.vaultId, vaultRevivalInfo);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success text="Successfully revived user's vault." />
        );
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit(data) {
    const vaultRevivalInfo = {
      hubspotTicket: data.supportTicket,
      revivalEndDate: moment(data?.reDisableDate).format("MM/DD/YYYY"),
    };
    mutate(vaultRevivalInfo);
    reset(data);
  }

  return (
    <Modal onClose={() => onCancel()}>
      <Modal.Header title="Revive Vault" onClose={() => onCancel()} />
      <Modal.Body>
        <p>
          Reviving the vault will give all users in the vault access to the
          vault again and any items previously shared with them. They can take
          any actions they previously had permission to take. Please enter the
          Support Ticket number to reference that contains all of the
          information and approvals needed to take this action. You'll also need
          to enter the date at which the vault should be disabled again.
        </p>
        <form className="mt-4">
          <p>
            <h3> Support Ticket Number </h3>
          </p>
          <FormField
            name="supportTicket"
            type="text"
            placeholder="Enter Support Ticket Number"
            size="normal"
            id="support-ticket"
            inputRef={register({
              required: "Please enter the hubspot ticket for this request",
            })}
          />

          <p>
            <h3>Re-disable Date</h3>
          </p>
          <FormField
            minDate={new Date()}
            name="reDisableDate"
            type="date"
            placeholder=""
            size="normal"
            id="revival-end-date"
            inputRef={register({
              required:
                "Please enter a valid date in the following format, XX/XX/XXXX.",
            })}
          />
        </form>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={!vault}
        isSubmitting={isLoading}
        submitText="Revive"
      />
    </Modal>
  );
}
