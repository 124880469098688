import React from "react";
import PropTypes from "prop-types";
import styles from "./Permissions.module.scss";

const PermissionPolicy = ({
  policyId,
  description,
}) => {
  return (
    <div className={styles.option}>
      <label>{description}</label>
    </div>
  );
};

PermissionPolicy.propTypes = {
  policyId: PropTypes.string,
  description: PropTypes.string,
};

export default PermissionPolicy;
