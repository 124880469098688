import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import styles from "./FloatingActionButton.module.scss";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";

export default function FloatingActionButton({ children }) {
  return <div className={styles.container}>{children}</div>;
}

function Secondary(btnText, btnType, icon = "add", onClick) {}

function Link({ onClick, ref, id, icon, text }) {
  return (
    <a className="dropdown-item p-2" id={id} onClick={onClick} ref={ref}>
      <span className="icon-text">
        <span className="icon">
          <span className={cx("material-icons-outlined", styles.linkIcon)}>
            {icon}
          </span>
        </span>
        <span className="is-size-6 pl-2">{text}</span>
      </span>
    </a>
  );
}

function Button({
  text,
  type,
  icon,
  onClick,
  id = "floating-action-button",
  disabled,
  children,
  parentRef,
}) {
  const menuRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(menuRef, false);

  function handleClick() {
    setIsActive(!isActive);
  }
  return (
    <div
      className={cx(
        "dropdown is-up is-right",
        { "is-active": isActive },
        styles.dropdownContainer
      )}
    >
      <div className="dropdown-trigger">
        <button
          className={cx("button", styles.actionBtn, styles[type])}
          aria-haspopup={!!children}
          disabled={disabled}
          aria-controls="dropdown-menu"
          id={id}
          onClick={onClick || handleClick}
          ref={parentRef || menuRef}
          id={id}
        >
          <span className="icon-text pl-3 pr-3">
            <span className="icon">
              <span className="material-icons-outlined">{icon}</span>
            </span>
            {text && <span className={styles.btnText}>{text}</span>}
          </span>
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className={`dropdown-content ${styles.dropdownContent}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

FloatingActionButton.Button = Button;

FloatingActionButton.Link = Link;

FloatingActionButton.Button.propTypes = {
  /**
   * Text to display next to the button icon
   */
  text: PropTypes.string,
  /**
   * What type of button should be displayed.  Primary, Secondary.
   */
  type: PropTypes.oneOf(["primary", "secondary", "danger", "dangerInverse"]),
  /**
   * What icon should be displayed on the button
   */
  icon: PropTypes.string,
};

FloatingActionButton.Button.defaultProps = {
  icon: "add",
  text: "",
  type: "primary",
};
