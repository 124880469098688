import { ActivityType } from "./activityType";

export default {
  add: [
    ActivityType.DOCUMENT_ADDED,
    ActivityType.PERSON_ADDED,
    ActivityType.IMAGE_ADDED_TO_PERSON,
    ActivityType.PLACE_ADDED,
    ActivityType.IMAGE_ADDED_TO_PLACE,
    ActivityType.THING_ADDED,
    ActivityType.IMAGE_ADDED_TO_THING,
    ActivityType.NOTE_ADDED,
    ActivityType.MEDIA_ADDED,
    ActivityType.ASSET_LINK_ADDED,
  ],
  update: [
    ActivityType.DOCUMENT_UPDATED,
    ActivityType.PERSON_UPDATED,
    ActivityType.PLACE_UPDATED,
    ActivityType.THING_UPDATED,
    ActivityType.NOTE_UPDATED,
    ActivityType.PERSON_ROLE_CHANGED,
  ],
  remove: [ActivityType.PERSON_INVITE_REMOVED_FROM_VAULT],
  delete: [
    ActivityType.DOCUMENT_DELETED,
    ActivityType.PERSON_DELETED,
    ActivityType.PLACE_DELETED,
    ActivityType.THING_DELETED,
    ActivityType.NOTE_DELETED,
    ActivityType.ASSET_LINK_REMOVED,
  ],
  share: [
    ActivityType.SHARE_PERMISSION_ADDED,
    ActivityType.SHARE_PERMISSION_REMOVED,
  ],
  watch: [ActivityType.MEDIA_VIDEO_WATCHED],
  view: [
    ActivityType.DOCUMENT_VIEWED,
    ActivityType.PERSON_VIEWED,
    ActivityType.PLACE_VIEWED,
    ActivityType.THING_VIEWED,
  ],
  download: [ActivityType.DOCUMENT_DOWNLOADED],
  billing: [ActivityType.INVOICE_PAID],
  terms: [ActivityType.USER_TERMS_ACCEPTED],
  signin: [
    ActivityType.USER_LOGIN_SUCCESSFUL,
    ActivityType.BIOMETRIC_LOGIN_SUCCESSFUL,
    ActivityType.USER_PROFILE_CREATED,
  ],
};
