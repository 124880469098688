import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Filter.module.scss";
import cx from "classnames";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";

export default function Filter({
  title,
  type,
  children,
  filterRef,
  isRight,
  active,
  onClick,
  IconComponent,
  id,
}) {
  const dropdownRef = useRef(filterRef);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, active);

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    onClick(filterRef, !isActive);
    setIsActive(!isActive);
  }

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  return (
    <div
      className={cx(
        "navbar-item has-dropdown",
        {
          "is-active": isActive,
          [styles.isActive]: isActive,
        },
        styles.itemWrapper
      )}
    >
      <a
        className={`navbar-link is-arrowless ${styles.filterItem}`}
        onClick={handleClick}
        ref={dropdownRef}
        id={id}
      >
        <span className="ml-2 mr-2">
          {IconComponent ? IconComponent() : title}
        </span>
        <span className={cx("icon", styles.expandMore)}>
          <span className="material-icons-outlined">expand_more</span>
        </span>
      </a>
      <div
        className={cx(`navbar-dropdown is-boxed ${styles.filterDropdown}`, {
          "is-right": isRight,
        })}
      >
        {children}
      </div>
    </div>
  );
}

function FilterItem({ name, value, ...otherProps }) {
  return (
    <a className={cx("navbar-item", styles.filterDropdownItem)} {...otherProps}>
      {name}
    </a>
  );
}

function SortItem({ name, value, selected, ...otherProps }) {
  return (
    <a
      className={cx(
        "navbar-item",
        selected ? styles.sortDropdownSelectedItem : styles.sortDropdownItem
      )}
      {...otherProps}
    >
      {name}
    </a>
  );
}

function HeaderItem({ title }) {
  return <span className={styles.sortHeader}>{title}</span>;
}

Filter.HeaderItem = HeaderItem;
Filter.FilterItem = FilterItem;
Filter.SortItem = SortItem;

Filter.propTypes = {
  /**
   * Title that will be displayed for the filter
   */
  title: PropTypes.string.isRequired,
};

Filter.FilterItem.propTypes = {
  //   title: PropTypes.string.isRequired,
  /**
   * The function that should fire upon clicking the X button in the Modal header
   */
  //   onClose: PropTypes.func.isRequired,
};
