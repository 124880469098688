import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Panel from "components/shared/panel/Panel";
import List from "components/shared/list/List";

export default function DashboardLoggedInUserInfo({}) {
  const { user } = useAuth0();

  return (
    <Panel hasPadding>
      <Panel.Title text="Your Info" />

      <List>
        {user &&
          Object.keys(user).map((key, index) => {
            return (
              <List.Row key={index}>
                <List.Text title={key} subtitle={String(user[key])} />
              </List.Row>
            );
          })}
      </List>
      <Panel.Footer />
    </Panel>
  );
}
