import { AgentActivityType } from "./agentActivityType";

export default {
  agent: [
    AgentActivityType.SEARCHED_AGENTS_ACTIVITY,
    AgentActivityType.RETRIEVED_AGENT_ACTIVITY,
  ],
  user: [
    AgentActivityType.LOCKED_USER,
    AgentActivityType.UNLOCKED_USER,
    AgentActivityType.END_USER_SESSION,
    AgentActivityType.RETRIEVED_USER_PROFILE_BY_USER_PROFILE_ID,
    AgentActivityType.RETRIEVED_VAULTS_FOR_USER_BY_USER_PROFILE_ID,
    AgentActivityType.VERIFIED_OTP_PROVIDED_BY_USER,
    AgentActivityType.SEARCHED_FOR_USER_PROFILES,
    AgentActivityType.VERIFIED_OTP_PROVIDED_BY_USER,
    AgentActivityType.SENT_VERIFICATION_CODE_TO_USER,
  ],
  vault: [
    AgentActivityType.VAULT_DISABLED,
    AgentActivityType.VAULT_RELEASED,
    AgentActivityType.VAULT_RELEASE_REVERTED,
    AgentActivityType.VAULT_REVIVED,
  ],
};
