import { get, del, add, update, patch } from "./serviceConfig";
const API_BASE_URL = process.env.REACT_APP_CUSTOMER_SUPPORT_SVC_API_URL;

export const supportService = {
  //Returns search results against: First, Last, Email, UserProfileId

  getUsers(searchTerm) {
    return add(`${API_BASE_URL}/support/v1/users`, {}, { search: searchTerm });
  },

  getUsersV2(request) {
    return add(
      `${API_BASE_URL}/support/v2/users`,
      {},
      {
        ...request,
      }
    );
  },

  getUsersV3(request) {
    return add(
      `${API_BASE_URL}/support/v3/users`,
      {},
      {
        ...request,
      }
    );
  },

  //Returns details about the user
  getUser(userProfileId) {
    return get(`${API_BASE_URL}/support/v1/users/${userProfileId}`);
  },

  //Returns vaults for the user
  getUserVaults(userProfileId) {
    return get(
      `${API_BASE_URL}/support/v2/vaults/forUser?userProfileId=${userProfileId}`
    );
  },

  //Returns all agents
  getAgents() {
    return get(`${API_BASE_URL}/support/v1/agents`);
  },

  //Returns all actvities for a given agent
  getAgentActivities(agentId) {
    return get(
      `${API_BASE_URL}/support/v1/agent/activities?csUserId=${agentId}`
    );
  },

  //Lock user account
  lockUser(userProfileId) {
    return update(
      `${API_BASE_URL}/support/v1/users/${userProfileId}/lock`,
      {},
      {}
    );
  },

  //Unlock user account
  unlockUser(userProfileId) {
    return update(
      `${API_BASE_URL}/support/v1/users/${userProfileId}/unlock`,
      {},
      {}
    );
  },

  //Send password reset to user
  sendPasswordReset(userProfileId) {
    return update(
      `${API_BASE_URL}/support/v1/users/${userProfileId}/sendPasswordReset`,
      {},
      {}
    );
  },

  // Send Verification Code
  // Emails a code to the user which can be given to the support rep as part of identifying themself on the call.
  // Method = email, sms, notification
  addCode(userProfileId, method) {
    return add(
      `${API_BASE_URL}/support/v1/users/${userProfileId}/sendCode?method=${method}`,
      {},
      {}
    );
  },

  // Verify Verification Code
  // Verifies a code given by a customer to the support-agent. Support agent never sees the code otherwise.
  updateCode(userProfileId, code) {
    return update(
      `${API_BASE_URL}/support/v1/users/${userProfileId}/verifyCode`,
      {},
      { code: code }
    );
  },

  // End User Session
  // Ends support agent's session with the user. All backend data related to the session will be deleted with this call.
  endUserSession(userProfileId) {
    return del(
      `${API_BASE_URL}/support/v1/users/${userProfileId}/endSession`,
      {},
      {}
    );
  },

  // Revive disabled vault
  // Revive a disabled vault, needs hubspot ticket and revival end date to proceed.
  reviveVault(vaultId, vaultRevivalInfo) {
    return update(
      `${API_BASE_URL}/support/v1/vaults/${vaultId}/revive`,
      {},
      { ...vaultRevivalInfo }
    );
  },

  // Disable revived vault
  // Disable a vault that was revived by mistake, needs hubspot ticket to proceed.
  disableVault(vaultId, supportTicket) {
    return update(
      `${API_BASE_URL}/support/v1/vaults/${vaultId}/disable`,
      {},
      { hubspotTicket: supportTicket }
    );
  },
  // Release the vault
  releaseVault(vaultId, revert = false) {
    return patch(
      `${API_BASE_URL}/support/v1/vaults/${vaultId}?revert=${revert}`,
      {}
    );
  },
};
