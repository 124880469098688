import React from "react";
import styles from "./CircleIcon.module.scss";
import cx from "classnames";

export default function CircleIcon({
  icon,
  containerClass = "",
  size = "medium",
  isClickable,
  onClick,
  children,
  ...otherProps
}) {
  return (
    <div
      className={cx([
        containerClass,
        styles.iconContainer,
        styles[size],
        { [styles.isClickable]: isClickable },
      ])}
      onClick={onClick}
      {...otherProps}
    >
      {icon && (
        <span className={cx(`icon`)}>
          <span
            className={cx("material-icons-outlined", styles.icon, styles[size])}
          >
            {icon}
          </span>
        </span>
      )}
      {children}
    </div>
  );
}

function ChildIcon({ icon = "camera_alt" }) {
  return (
    <span className={cx("icon", styles.childIcon)}>
      <span className={cx("material-icons-outlined")}>{icon}</span>
    </span>
  );
}

CircleIcon.ChildIcon = ChildIcon;
