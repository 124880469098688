import React from "react";
import moment from "moment";

export function getAgentActivityDetail(activity) {
  const actingUserShortName = `${
    activity?.actingUser?.userProfileFirstName?.split(" ")?.[0]
  } ${activity?.actingUser?.userProfileLastName?.split(" ")?.[0]}`;
  const header = `${activity?.actingUser?.userProfileFirstName} ${activity?.actingUser?.userProfileLastName}`;
  const date = moment(parseInt(activity?.timestamp)).format(
    "M/D/YYYY @ h:mm A"
  );
  const ipAddress = activity?.ipAddress;
  const clientName = activity.data?.clientName;
  let action = (
    <>{activity?.data?.activityDescription ?? activity?.activityType}</>
  );

  return { actingUserShortName, header, action, date, ipAddress, clientName };
}
