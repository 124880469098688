import React, { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Modal from "components/shared/modal/Modal";
import Message from "components/shared/toast/Message";
import {disableVault, useUser} from "stores/supportStore";
import FormField from "components/shared/formfield/FormField";

// TODO implement disableVault and reviveVault api calls and add buttons in User.js based on vault status
export default function DisableVaultModal({ user, vault, onCancel, onDone }) {
  const { handleSubmit, register, errors, getValues } = useForm();

  const {
    data: vaultOwnerData,
  } = useUser(vault?.vault?.vaultOwnerUserProfileId);

  const { mutate, isLoading } = useMutation(
    (supportTicket) => {
      return disableVault(vault?.vault?.vaultId, supportTicket);
    },
    {
      onSuccess: (response) => {
        toast.success(
          <Message.Success text="Successfully disabled user's vault." />
        );
        onDone();
      },
      onError: (error) => {
        toast.error(`${error?.response?.data?.errorMessage}`);
      },
    }
  );

  function onSubmit(data) {
    mutate(data.supportTicket);
  }

  return (
    <Modal onClose={() => onCancel()}>
      <Modal.Header className="has-text-left" title="Disable User Vault" onClose={() => onCancel()} />
      <Modal.Body>
        <p className="has-text-left">
          Disable <strong> {`${vaultOwnerData?.data?.userProfile?.firstName} ${vaultOwnerData?.data?.userProfile?.lastName}`}</strong>'s vault?
        <form className="mt-4 has-text-left">
        <p><h3> Support Ticket Number </h3></p>
        <FormField
            name="supportTicket"
            type="text"
            placeholder="Enter Support Ticket Number"
            size="normal"
            id="support-ticket"
            inputRef={register({
              required: "Please enter the hubspot ticket for this request",
            })}
        />
        </form>
        </p>
      </Modal.Body>
      <Modal.Footer
        onCancel={() => onCancel()}
        onSubmit={handleSubmit(onSubmit)}
        isDisabled={!vault}
        isSubmitting={isLoading}
        submitText="Disable"
      />
    </Modal>
  );
}
