import PropTypes from "prop-types";
import useCustomerSupportPermissions from "hooks/useCustomerSupportPermissions";

const CustomerSupportPermissionValidator = ({
  children,
  permissions,
  additionalCheck = true,
  fallbackComponent = null,
}) => {
  const { authorized } = useCustomerSupportPermissions(permissions);

  if (authorized && additionalCheck) {
    return children;
  } else {
    return fallbackComponent;
  }
};

CustomerSupportPermissionValidator.propTypes = {
  permissions: PropTypes.array,
  children: PropTypes.node,
  additionalCheck: PropTypes.bool,
  fallbackComponent: PropTypes.node,
};
export default CustomerSupportPermissionValidator;
