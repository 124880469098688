import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "util/router";
import { NavLink as RouterNavLink } from "react-router-dom";
import styles from "./Navbar.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import cx from "classnames";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Avatar from "../avatar/Avatar";

function AccountDropdown({}) {
  const [isOpen, setIsOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);

  function handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setIsActive(!isActive);
  }

  return (
    // <>
    //   {!isAuthenticated && (
    //     <Nav className="d-md-none" navbar>
    //       <NavItem>
    //         <Button
    //           id="qsLoginBtn"
    //           color="primary"
    //           block
    //           onClick={() => loginWithRedirect({})}
    //         >
    //           Log in
    //         </Button>
    //       </NavItem>
    //     </Nav>
    //   )}
    //   {isAuthenticated && (
    //     <Nav
    //       className="d-md-none justify-content-between"
    //       navbar
    //       style={{ minHeight: 170 }}
    //     >
    //       <NavItem>
    //         <span className="user-info">
    //           <img
    //             src={user.picture}
    //             alt="Profile"
    //             className="nav-user-profile d-inline-block rounded-circle mr-3"
    //             width="50"
    //           />
    //           <h6 className="d-inline-block">{user.name}</h6>
    //         </span>
    //       </NavItem>
    //       <NavItem>
    //         <RouterNavLink
    //           to="/profile"
    //           activeClassName="router-link-exact-active"
    //         >
    //           Profile
    //         </RouterNavLink>
    //       </NavItem>
    //       <NavItem>
    //         <RouterNavLink
    //           to="#"
    //           id="qsLogoutBtn"
    //           onClick={() => logoutWithRedirect()}
    //         >
    //           Log out
    //         </RouterNavLink>
    //       </NavItem>
    //     </Nav>
    //   )}
    // </>
    <div
      className={cx("navbar-item has-dropdown", { "is-active": isActive })}
      onClick={handleClick}
      ref={dropdownRef}
      id="navbar-account-dropdown-hover"
    >
      <span
        className="navbar-link is-arrowless"
        id="navbar-account-dropdown-btn"
        onClick={() => {
          menuOpen && setMenuOpen(!menuOpen);
        }}
      >
        <Avatar
          name={user?.name}
          size="medium"
          isClickable
          hasBorder
          src={user?.picture}
        />
      </span>
      <div className={`navbar-dropdown is-boxed is-right ${styles.dropdown}`}>
        <hr className="dropdown-divider is-hidden-mobile" />
        <Link
          className="navbar-item p-3"
          to="/"
          id="navbar-logout-btn"
          onClick={(e) => {
            e.preventDefault();
            logoutWithRedirect();
          }}
        >
          <span className="icon-text">
            <span className="icon">
              <span className="material-icons-outlined">
                power_settings_new
              </span>
            </span>
            <span className="is-size-6 pl-2">Log Out</span>
          </span>
        </Link>
      </div>
    </div>
  );
}

AccountDropdown.propTypes = {};

export default AccountDropdown;
