import React, { useMemo } from "react";
import List from "components/shared/list/List";
import { history } from "util/router";

export default function User({ user, ...props }) {
  const isUnregistered = !user?.userProfile;
  const name = user?.userProfile
    ? `${user?.userProfile?.firstName} ${user?.userProfile?.lastName}`
    : "UNREGISTERED";
  const email = user?.userProfile
    ? user?.userProfile?.email
    : user?.orgUserInfoList?.[0]?.emailId;
  const userProfileId = user?.userProfile
    ? user?.userProfile?.userProfileId
    : "--";
  const status = user?.userProfile?.userStatus ?? "--";
  const orgId = user?.orgUserInfoList?.[0]?.orgId ?? "--";

  const isActive = useMemo(() => status === "ACTIVE", [status]);

  function handleRowClick() {
    if (isUnregistered) return;

    history.push(`/users/${userProfileId}`);
  }

  return (
    <List.Row isClickable={!isUnregistered} onClick={handleRowClick} {...props}>
      <List.Text title={name} hasRedTitle={isUnregistered} />
      <List.Text title={email} hasRedTitle={isUnregistered} />
      <List.Text
        title={userProfileId}
        hasRedTitle={isUnregistered}
        shrinkPadding
      />
      <List.Text
        title={status}
        hasRedTitle={!isActive}
        hasGreenTitle={isActive}
        shrinkPadding
      />
      <List.Text title={orgId} hasRedTitle={isUnregistered} shrinkPadding />
    </List.Row>
  );
}
