import { useState, useMemo, useEffect } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export default function WithAxios({ children }) {
  const { getAccessTokenSilently } = useAuth0();

  const [token, setToken] = useState(null);

  useEffect(() => {
    async function getToken() {
      const token = await getAccessTokenSilently();
      setToken(token);
    }
    getToken();
  }, []);

  useMemo(() => {
    axios.interceptors.request.use(async (config) => {
      if (!token) return config;
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }, [token]);

  return children;
}
