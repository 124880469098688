import React from "react";
import PropTypes from "prop-types";

function LogoSvg({ className, showText = false, reverse = false }) {
  const typeColor = reverse ? "#fff" : "#212C55";

  return (
    <svg
      className={className}
      viewBox="0 0 244 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_204_229)">
        {/* Prisidio Text */}
        {showText && (
          <>
            <path
              d="M98.5235 32.5091C98.5235 40.9293 92.3548 46.5427 85.4354 46.5427C81.4461 46.5427 78.171 45.1455 75.8646 42.7354V54.9019H70.3315V19.2138H75.8827V22.2646C78.165 19.8545 81.4642 18.4572 85.4536 18.4572C92.3548 18.4633 98.5235 24.1622 98.5235 32.5091ZM93.1539 32.5091C93.1539 26.9323 89.3764 23.3934 84.5334 23.3934C82.2747 23.5976 80.1735 24.6464 78.6432 26.3334C77.1129 28.0203 76.2643 30.2234 76.2643 32.5091C76.2643 34.7949 77.1129 36.998 78.6432 38.6849C80.1735 40.3719 82.2747 41.4206 84.5334 41.6249C89.3764 41.6249 93.1539 38.1409 93.1539 32.5091Z"
              fill={typeColor}
            />
            <path
              d="M126.031 11.2269C126.031 12.1719 125.659 13.0783 124.996 13.7465C124.333 14.4148 123.434 14.7902 122.496 14.7902C121.558 14.7902 120.659 14.4148 119.996 13.7465C119.333 13.0783 118.961 12.1719 118.961 11.2269C118.961 10.2818 119.333 9.3755 119.996 8.70724C120.659 8.03899 121.558 7.66357 122.496 7.66357C123.434 7.66357 124.333 8.03899 124.996 8.70724C125.659 9.3755 126.031 10.2818 126.031 11.2269ZM119.76 45.8106V19.2138H125.287V45.8106H119.76Z"
              fill={typeColor}
            />
            <path
              d="M160.598 11.2269C160.56 12.1472 160.171 13.0172 159.511 13.6549C158.852 14.2927 157.973 14.6487 157.059 14.6487C156.146 14.6487 155.267 14.2927 154.608 13.6549C153.948 13.0172 153.559 12.1472 153.521 11.2269C153.559 10.3066 153.948 9.43657 154.608 8.79884C155.267 8.16111 156.146 7.80502 157.059 7.80502C157.973 7.80502 158.852 8.16111 159.511 8.79884C160.171 9.43657 160.56 10.3066 160.598 11.2269ZM154.32 45.8106V19.2138H159.853V45.8106H154.32Z"
              fill={typeColor}
            />
            <path
              d="M191.381 45.8106H185.799V42.8025C184.535 44.0447 183.035 45.0177 181.39 45.6634C179.745 46.3092 177.987 46.6144 176.222 46.5611C169.364 46.5611 163.134 40.9293 163.134 32.5275C163.134 24.1256 169.364 18.4938 176.222 18.4938C180.212 18.4938 183.456 19.8911 185.799 22.3012V7.20596H191.381V45.8106ZM185.799 32.5091C185.837 31.333 185.641 30.161 185.224 29.0621C184.806 27.9633 184.174 26.9597 183.366 26.1104C182.558 25.2611 181.589 24.5833 180.517 24.1167C179.445 23.6501 178.292 23.4042 177.124 23.3934C172.342 23.3934 168.564 26.9323 168.564 32.5091C168.564 38.086 172.342 41.6249 177.124 41.6249C181.543 41.6249 185.799 38.1409 185.799 32.5091Z"
              fill={typeColor}
            />
            <path
              d="M202.102 11.2269C202.064 12.1472 201.675 13.0172 201.015 13.6549C200.356 14.2927 199.477 14.6487 198.563 14.6487C197.649 14.6487 196.771 14.2927 196.112 13.6549C195.452 13.0172 195.063 12.1472 195.025 11.2269C195.063 10.3066 195.452 9.43657 196.112 8.79884C196.771 8.16111 197.649 7.80502 198.563 7.80502C199.477 7.80502 200.356 8.16111 201.015 8.79884C201.675 9.43657 202.064 10.3066 202.102 11.2269ZM195.824 45.8106V19.2138H201.357V45.8106H195.824Z"
              fill={typeColor}
            />
            <path
              d="M232.951 32.5091C232.951 40.7707 226.619 46.4512 218.852 46.4512C211.085 46.4512 204.65 40.7707 204.65 32.5091C204.65 24.2476 211.031 18.622 218.852 18.622C226.674 18.622 232.951 24.2537 232.951 32.5091ZM227.576 32.5091C227.518 30.7765 226.956 29.0992 225.959 27.6867C224.962 26.2742 223.574 25.1891 221.968 24.5669C220.363 23.9448 218.611 23.8131 216.932 24.1884C215.253 24.5637 213.72 25.4292 212.526 26.677C211.332 27.9248 210.529 29.4995 210.218 31.2044C209.906 32.9094 210.1 34.6691 210.775 36.2637C211.45 37.8583 212.576 39.2172 214.012 40.1707C215.449 41.1241 217.132 41.6299 218.852 41.6249C220.024 41.6198 221.182 41.3781 222.259 40.9141C223.336 40.4501 224.31 39.7732 225.124 38.9234C225.937 38.0736 226.573 37.0682 226.994 35.9665C227.415 34.8648 227.613 33.6892 227.576 32.5091Z"
              fill={typeColor}
            />
            <path
              d="M144.198 30.7946L137.921 29.4035C135.578 28.8665 134.73 27.9025 134.73 26.4015C134.73 24.5222 136.165 22.9114 139.41 22.9114C142.285 22.9114 144.283 24.1805 145.094 26.1208L150.028 24.1988C148.43 20.7819 144.713 18.4633 139.573 18.4633C133.241 18.4633 129.143 21.9473 129.143 26.8835C129.143 30.636 131.752 33.101 136.323 34.12L142.068 35.2976C144.519 35.8345 145.633 36.8535 145.633 38.4643C145.633 40.551 143.611 42.0032 140.421 42.0032C137.533 42.0032 135.13 41.0575 134.198 38.9036L128.671 40.3131C130.087 44.5536 134.676 46.5611 139.943 46.5611C146.541 46.5611 151.063 43.5591 151.063 37.9823C151.063 34.2298 148.454 31.7098 144.198 30.7946Z"
              fill={typeColor}
            />
            <path
              d="M107.253 24.8944V19.2138H101.72V45.8106H107.253V36.4264C107.253 29.3302 110.383 24.6869 116.721 24.4368V18.9149C112.447 19.1162 109.287 21.5141 107.253 24.8944Z"
              fill={typeColor}
            />
          </>
        )}
        {/* Shield */}
        <path
          d="M28.6794 57.1153L28.0183 57.3733V53.9786C36.9386 50.0831 46.7231 43.3257 46.7231 39.8006V13.5894C40.7402 11.3405 34.4132 10.1438 28.0222 10.0524V6.95146C35.146 7.05677 42.192 8.44939 48.8202 11.0621L49.824 11.4445V39.8006C49.824 46.6655 34.5586 54.6239 28.6794 57.1153ZM55.5 42.4175V6.96342L53.6419 6.25242C49.4595 4.63327 39.7028 0.845282 28.0222 0.833332V3.91333C39.0157 3.91333 48.3363 7.51906 52.3991 9.09639V42.4175C52.3991 47.263 39.6272 56.01 28.0183 61.0408V64.4867L28.6973 64.1775C35.6519 61.2618 55.5 50.9554 55.5 42.4175ZM44.0106 37.4226V16.0331L43.0128 15.6507C38.2007 13.9457 33.1272 13.0964 28.0222 13.1413V16.2422C32.3952 16.2211 36.7442 16.8885 40.9097 18.2198V37.4465C40.9097 39.6751 35.0267 44.0187 28.0183 47.0957L28.0183 50.5167L28.6376 50.2384C30.2269 49.5872 44.0106 43.5646 44.0106 37.4465V37.4226Z"
          fill="#EC5024"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.57955 9.06652V35.9528C0.908839 34.3038 0.460733 32.2126 0.502556 30.6652V6.96342L2.33082 6.25839C6.45937 4.63327 16.2485 0.833332 28.0367 0.833332V3.91033C16.9655 3.91033 7.65431 7.47724 3.57955 9.06652ZM18.128 44.9986V41.2644C21.3109 43.4017 24.6177 45.3536 28.0367 47.0883L28.0183 50.5133L27.353 50.1906C25.5546 49.2825 21.5635 47.2033 18.128 44.9986ZM18.128 48.6491C21.5456 50.7523 25.226 52.6009 27.4426 53.6883L28.0367 53.9817L28.0162 57.3782L27.3769 57.0556L26.0744 56.4163L25.5851 56.1727C23.668 55.2191 20.9106 53.8476 18.128 52.234V48.6491ZM26.9906 60.4991L27.0961 60.5508L28.0183 61.04V64.4822L27.4008 64.1596L25.7398 63.3351L25.6018 63.2673L25.6017 63.2673L25.6016 63.2672C23.6967 62.3328 21.0093 61.0144 18.134 59.4455V55.9025C21.5034 57.8077 24.7859 59.4177 26.9906 60.4991ZM9.26152 45.9007C6.58484 44.2517 6.13674 42.1606 6.13674 40.6071V11.4445L7.13452 11.0621C13.7799 8.4299 20.8895 7.03672 28.0367 6.95146V10.0523C21.625 10.1261 15.2374 11.3212 9.23762 13.5834L9.26152 45.9007ZM15.069 18.2138V58.4477C12.3923 56.7987 11.9502 54.7075 11.9681 53.1541V16.057L12.9659 15.6746C17.7962 13.9499 22.9079 13.0923 28.0367 13.1413V16.2422C23.6418 16.2103 19.2553 16.8757 15.069 18.2138Z"
          fill="#F69320"
        />
        {/* (r) */}
        {showText && (
          <path
            d="M238.5 27C240.986 27 243 24.9863 243 22.5C243 20.0138 240.986 18 238.5 18C236.014 18 234 20.0138 234 22.5C234 24.9863 236.014 27 238.5 27ZM238.5 25.92C236.61 25.92 235.08 24.39 235.08 22.5C235.08 20.61 236.61 19.08 238.5 19.08C240.39 19.08 241.92 20.61 241.92 22.5C241.92 24.39 240.39 25.92 238.5 25.92ZM236.925 24.525H237.836V23.1188H238.742L239.468 24.525H240.469L239.619 22.9163C239.985 22.6913 240.3 22.275 240.3 21.7013C240.3 20.8013 239.535 20.3737 239.029 20.3737H236.925V24.525ZM237.836 22.4438V21.0488H238.703C238.916 21.0488 239.299 21.195 239.299 21.7013C239.299 22.1963 238.928 22.4438 238.68 22.4438H237.836Z"
            fill={typeColor}
          />
        )}
      </g>
      <defs>
        <clipPath id="clip0_204_229">
          <rect width="244" height="65" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

LogoSvg.propTypes = {
  /**
   * Css class name to use on the SVG
   */
  className: PropTypes.string,
  /**
   * Show the Logo Type
   */
  showText: PropTypes.bool,
  /**
   * Show the Logo Type in white.  Requires showText to be true
   */
  reverse: PropTypes.bool,
};

export default React.memo(LogoSvg);
