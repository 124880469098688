import React from "react";
import PropTypes from "prop-types";
import Modal from "components/shared/modal/Modal";

function VaultDataModal({ vault, onCancel }) {
  return (
    <Modal onClose={() => onCancel()} isLarge>
      <Modal.Header title="Vault API Data" onClose={() => onCancel()} />
      <Modal.Body hasScroll>
        <pre
          style={{
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            margin: "8px 0 0 0",
          }}
        >
          {JSON.stringify(vault, null, "\t")}
        </pre>
      </Modal.Body>
      <Modal.Footer onCancel={() => onCancel()} cancelText="Close" hideSubmit />
    </Modal>
  );
}

VaultDataModal.propTypes = {};

export default VaultDataModal;
