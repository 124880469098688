export const VaultRoleEnum = {
  ROLE_VAULT_OWNER: "ROLE_VAULT_OWNER",
  ROLE_VAULT_CO_OWNER: "ROLE_VAULT_CO_OWNER",
  ROLE_VAULT_KEY_HOLDER: "ROLE_VAULT_KEY_HOLDER",
  ROLE_VAULT_CONTRIBUTOR: "ROLE_VAULT_CONTRIBUTOR",
  ROLE_VAULT_MEMBER: "ROLE_VAULT_MEMBER",
  ROLE_INVITED_USER: "vault-invited-user", //This is probably going to be removed
  ROLE_INVITED_USER_ENUM: "ROLE_INVITED_USER",
  ROLE_ASSET_OWNER: "ROLE_ASSET_OWNER",
};
