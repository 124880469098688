import React from "react";
import { useAgents } from "stores/supportStore";
import List from "components/shared/list/List";
import NoResults from "components/shared/noresults/NoResults";
import PageLoader from "components/shared/pageloader/PageLoader";
import Agent from "./Agent";

function Agents() {
  const { data: agents, error, isLoading } = useAgents();

  return (
    <div className="container mt-4">
      <List hasBorder hideLastRowBorder hasBottomMargin>
        <List.Row>
          <List.Text title="Name" shrinkPadding />
          <List.Text title="UserId" increasePadding />
          <List.Text title="Last LogIn" />
        </List.Row>

        {error && (
          <p className="has-text-centered has-text-danger mt-4 mb-4">{`There was an error attempting to retrieve agents`}</p>
        )}
        {isLoading && <PageLoader />}
        {agents?.data?.length === 0 && !isLoading && !error ? (
          <NoResults subtitle="There are no agents." />
        ) : (
          <>
            {agents?.data?.map((agent, index) => (
              <Agent key={agent.userId} agent={agent} />
            ))}
          </>
        )}
      </List>
    </div>
  );
}

Agents.propTypes = {};

export default Agents;
