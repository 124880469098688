import React from "react";
import { Link } from "react-router-dom";
import styles from "./TextLink.module.scss";
import cx from "classnames";

export default function TextLink({ url, text, id, hasChevron, openNewWindow }) {
  return (
    <>
      {openNewWindow ? (
        <a
          className={styles.link}
          href={url}
          id={id}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
          <span className={cx(styles.icon, "icon")}>
            <span className="material-icons-outlined">launch</span>
          </span>
        </a>
      ) : (
        <Link to={url} className={styles.link} id={id}>
          {text}
          {hasChevron && (
            <span className={cx(styles.icon, "icon")}>
              <span className="material-icons-outlined">chevron_right</span>
            </span>
          )}
        </Link>
      )}
    </>
  );
}
