import React from "react";
import PropTypes from "prop-types";

function Logo({ className, showText = false, reverse = false }) {
  const typeColor = reverse ? "#fff" : "#212C55";

  return (
    <svg className={className} viewBox="0 0 238 65">
      <g clipPath="url(#clip0)">
        <path
          d="M28.5915 57.4768L27.9437 57.7513V54.2307L28.0224 54.2735C37.0606 50.2952 46.8736 43.3944 46.8736 39.7944V13.0268C40.8116 10.7302 34.401 9.50813 27.9256 9.41472V6.24801C35.1435 6.35555 42.2826 7.77772 48.9984 10.4459L50.0154 10.8364V39.7944C50.0154 46.8051 34.5483 54.9324 28.5915 57.4768ZM55.7664 42.4669V6.26021L53.8837 5.53412C49.6461 3.8806 39.7605 0.0121993 27.9256 -3.8147e-06V3.16671C39.0643 3.16671 48.508 6.82765 52.6245 8.43847V42.4669C52.6245 47.4153 39.815 56.348 28.0527 61.4855L27.9437 61.4306V64.9756L28.6096 64.6888C35.6561 61.7113 55.7664 51.1861 55.7664 42.4669ZM44.1252 37.366V15.5224L43.1142 15.1319C38.2385 13.3907 33.0981 12.5234 27.9256 12.5692V15.7359C32.3563 15.7145 36.7628 16.396 40.9833 17.7556V37.3904C40.9833 39.6663 35.1113 44.1021 28.0103 47.2444L27.9437 47.2078V50.7162L28.5491 50.4539C30.1594 49.7888 44.1252 43.6384 44.1252 37.3904V37.366Z"
          fill="#EC5024"
        />
        <path
          d="M17.9007 41.2893V45.1028C21.3816 47.3543 25.4254 49.4776 27.2476 50.4051L27.9014 50.7345H27.9437V47.2261C24.4795 45.4546 21.1257 43.472 17.9007 41.2893ZM27.3384 53.9318C25.0925 52.8213 21.3634 50.9786 17.9007 48.8308V52.4918C20.9699 54.2856 24.0088 55.7866 25.9521 56.7629L27.2718 57.4157L27.9195 57.7452V54.2246L27.3384 53.9318ZM26.9872 60.9852C24.7534 59.8808 21.3755 58.2151 17.9068 56.2381V59.8564C20.8912 61.4977 23.6759 62.8706 25.6131 63.8285L27.296 64.6705L27.9498 65V61.455L26.9872 60.9852Z"
          fill="#F7941D"
        />
        <path
          d="M3.15964 35.865V8.40796C7.28824 6.78494 16.7077 3.14231 27.9252 3.14231V-3.8147e-06C15.9813 -3.8147e-06 6.0775 3.88059 1.89442 5.54022L0.0419958 6.26021V30.4651C-0.000379838 32.0454 0.453647 34.181 3.15964 35.865Z"
          fill="#F7941D"
        />
        <path
          d="M8.91705 46.0241C6.20501 44.3401 5.75098 42.2045 5.75098 40.6181V10.8364L6.76195 10.4459C13.4951 7.75783 20.6597 6.33508 27.9014 6.248V9.41472C21.405 9.49007 14.9718 10.7105 8.89284 13.0207L8.91705 46.0241Z"
          fill="#F7941D"
        />
        <path
          d="M14.8012 58.8374V17.7495C19.0428 16.383 23.4727 15.7034 27.9256 15.7359V12.5692C22.729 12.5192 17.5645 13.395 12.6703 15.1563L11.6594 15.5468V53.4314C11.6412 55.0178 12.0892 57.1534 14.8012 58.8374Z"
          fill="#F7941D"
        />
        {showText && (
          <>
            <path
              d="M98.5235 32.5091C98.5235 40.9293 92.3548 46.5427 85.4354 46.5427C81.4461 46.5427 78.171 45.1455 75.8646 42.7354V54.9019H70.3315V19.2138H75.8827V22.2646C78.165 19.8545 81.4642 18.4572 85.4536 18.4572C92.3548 18.4633 98.5235 24.1622 98.5235 32.5091ZM93.1539 32.5091C93.1539 26.9323 89.3764 23.3934 84.5334 23.3934C82.2747 23.5976 80.1735 24.6464 78.6432 26.3334C77.1129 28.0203 76.2643 30.2234 76.2643 32.5091C76.2643 34.7949 77.1129 36.998 78.6432 38.6849C80.1735 40.3719 82.2747 41.4206 84.5334 41.6249C89.3764 41.6249 93.1539 38.1409 93.1539 32.5091Z"
              fill={typeColor}
            />
            <path
              d="M126.031 11.2269C126.031 12.1719 125.659 13.0783 124.996 13.7465C124.333 14.4148 123.434 14.7902 122.496 14.7902C121.558 14.7902 120.659 14.4148 119.996 13.7465C119.333 13.0783 118.961 12.1719 118.961 11.2269C118.961 10.2818 119.333 9.3755 119.996 8.70724C120.659 8.03899 121.558 7.66357 122.496 7.66357C123.434 7.66357 124.333 8.03899 124.996 8.70724C125.659 9.3755 126.031 10.2818 126.031 11.2269ZM119.76 45.8106V19.2138H125.287V45.8106H119.76Z"
              fill={typeColor}
            />
            <path
              d="M160.598 11.2269C160.56 12.1472 160.171 13.0172 159.511 13.6549C158.852 14.2927 157.973 14.6487 157.059 14.6487C156.146 14.6487 155.267 14.2927 154.608 13.6549C153.948 13.0172 153.559 12.1472 153.521 11.2269C153.559 10.3066 153.948 9.43657 154.608 8.79884C155.267 8.16111 156.146 7.80502 157.059 7.80502C157.973 7.80502 158.852 8.16111 159.511 8.79884C160.171 9.43657 160.56 10.3066 160.598 11.2269ZM154.32 45.8106V19.2138H159.853V45.8106H154.32Z"
              fill={typeColor}
            />
            <path
              d="M191.381 45.8106H185.799V42.8025C184.535 44.0447 183.035 45.0177 181.39 45.6634C179.745 46.3092 177.987 46.6144 176.222 46.5611C169.364 46.5611 163.134 40.9293 163.134 32.5275C163.134 24.1256 169.364 18.4938 176.222 18.4938C180.212 18.4938 183.456 19.8911 185.799 22.3012V7.20596H191.381V45.8106ZM185.799 32.5091C185.837 31.333 185.641 30.161 185.224 29.0621C184.806 27.9633 184.174 26.9597 183.366 26.1104C182.558 25.2611 181.589 24.5833 180.517 24.1167C179.445 23.6501 178.292 23.4042 177.124 23.3934C172.342 23.3934 168.564 26.9323 168.564 32.5091C168.564 38.086 172.342 41.6249 177.124 41.6249C181.543 41.6249 185.799 38.1409 185.799 32.5091Z"
              fill={typeColor}
            />
            <path
              d="M202.102 11.2269C202.064 12.1472 201.675 13.0172 201.015 13.6549C200.356 14.2927 199.477 14.6487 198.563 14.6487C197.649 14.6487 196.771 14.2927 196.112 13.6549C195.452 13.0172 195.063 12.1472 195.025 11.2269C195.063 10.3066 195.452 9.43657 196.112 8.79884C196.771 8.16111 197.649 7.80502 198.563 7.80502C199.477 7.80502 200.356 8.16111 201.015 8.79884C201.675 9.43657 202.064 10.3066 202.102 11.2269ZM195.824 45.8106V19.2138H201.357V45.8106H195.824Z"
              fill={typeColor}
            />
            <path
              d="M232.951 32.5091C232.951 40.7707 226.619 46.4512 218.852 46.4512C211.085 46.4512 204.65 40.7707 204.65 32.5091C204.65 24.2476 211.031 18.622 218.852 18.622C226.674 18.622 232.951 24.2537 232.951 32.5091ZM227.576 32.5091C227.518 30.7765 226.956 29.0992 225.959 27.6867C224.962 26.2742 223.574 25.1891 221.968 24.5669C220.363 23.9448 218.611 23.8131 216.932 24.1884C215.253 24.5637 213.72 25.4292 212.526 26.677C211.332 27.9248 210.529 29.4995 210.218 31.2044C209.906 32.9094 210.1 34.6691 210.775 36.2637C211.45 37.8583 212.576 39.2172 214.012 40.1707C215.449 41.1241 217.132 41.6299 218.852 41.6249C220.024 41.6198 221.182 41.3781 222.259 40.9141C223.336 40.4501 224.31 39.7732 225.124 38.9234C225.937 38.0736 226.573 37.0682 226.994 35.9665C227.415 34.8648 227.613 33.6892 227.576 32.5091Z"
              fill={typeColor}
            />
            <path
              d="M144.198 30.7946L137.921 29.4035C135.578 28.8665 134.73 27.9025 134.73 26.4015C134.73 24.5222 136.165 22.9114 139.41 22.9114C142.285 22.9114 144.283 24.1805 145.094 26.1208L150.028 24.1988C148.43 20.7819 144.713 18.4633 139.573 18.4633C133.241 18.4633 129.143 21.9473 129.143 26.8835C129.143 30.636 131.752 33.101 136.323 34.12L142.068 35.2976C144.519 35.8345 145.633 36.8535 145.633 38.4643C145.633 40.551 143.611 42.0032 140.421 42.0032C137.533 42.0032 135.13 41.0575 134.198 38.9036L128.671 40.3131C130.087 44.5536 134.676 46.5611 139.943 46.5611C146.541 46.5611 151.063 43.5591 151.063 37.9823C151.063 34.2298 148.454 31.7098 144.198 30.7946Z"
              fill={typeColor}
            />
            <path
              d="M233.06 19.6348H232.067V19.281H234.453V19.6409H233.46V22.4965H233.06V19.6348ZM235.016 19.2748H235.536L236.499 21.9596L237.467 19.2748H238V22.4904H237.588V19.9704L236.662 22.4904H236.305L235.379 19.9704V22.4904H234.997L235.016 19.2748Z"
              fill={typeColor}
            />
            <path
              d="M107.253 24.8944V19.2138H101.72V45.8106H107.253V36.4264C107.253 29.3302 110.383 24.6869 116.721 24.4368V18.9149C112.447 19.1162 109.287 21.5141 107.253 24.8944Z"
              fill={typeColor}
            />
          </>
        )}
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="238" height="65" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Logo.propTypes = {
  /**
   * Css class name to use on the SVG
   */
  className: PropTypes.string,
  /**
   * Show the Logo Type
   */
  showText: PropTypes.bool,
  /**
   * Show the Logo Type in white.  Requires showText to be true
   */
  reverse: PropTypes.bool,
};

export default React.memo(Logo);
